import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormatter } from '../../hooks/useFormatter'
import { useDataContext } from '../../contexts/DataContext'
import User from '../../models/User'

type Props = {
  user: User
}

const UserListCell = ({ user }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { dateTime, infoRole } = useFormatter()
  const { currentUserId } = useDataContext()

  const editUser = () => {
    navigate(`/user/${user.id}`)
  }

  return (
    <div className='cell'>
      <div style={{ display: 'none' }}>{user.id}</div>
      <table className='table-cell'>
        <tbody>
          <tr>
            <td className='td-cell-label'>{t('label.username')}:</td>
            <td className='td-cell-value'>{user.username}</td>
          </tr>
          <tr>
            <td className='td-cell-label'>{t('label.full_name')}:</td>
            <td className='td-cell-value'>{`${user.firstName} ${user.lastName}`}</td>
          </tr>
          <tr>
            <td className='td-cell-label'>{t('label.email')}:</td>
            <td className='td-cell-value'>{user.email}</td>
          </tr>
          <tr>
            <td className='td-cell-label'>{t('label.role')}:</td>
            <td className='td-cell-value'>{infoRole(user.role)}</td>
          </tr>
          <tr>
            <td className='td-cell-label'>{t('label.created')}:</td>
            <td className='td-cell-value'>{dateTime(user.created ?? '')}</td>
          </tr>
        </tbody>
      </table>

      {user.id !== currentUserId && (
        <button type='button' onClick={editUser}>
          {t('button.edit_this_user')}
        </button>
      )}
    </div>
  )
}

export default UserListCell
