import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormatter } from '../../hooks/useFormatter'
import { useNeuroTranslation } from '../../hooks/useNeuroTranslation'
import { useDataContext } from '../../contexts/DataContext'
import Patient from '../../models/Patient'

type Props = {
  patient: Patient
}

const PatientListCell = ({ patient }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { naPosNeg, sex } = useFormatter()
  const { translate } = useNeuroTranslation()

  const { currentUserRole } = useDataContext()

  const editPatient = () => {
    navigate(`/patient/${patient.id}`)
  }

  const showEditButton =
    (currentUserRole === 'client' && patient.probCtrl === null) ||
    (currentUserRole === 'neuro' && patient.probCtrl !== null && patient.neuroEval === null)

  const format = (prob: number | null) => (prob !== null ? (prob * 100).toFixed(1) : '--')

  return (
    <div className='cell'>
      <div style={{ display: 'none' }}>{patient.id}</div>
      <table className='table-cell'>
        <tbody>
          <tr>
            <td className='td-cell-label'>{t('label.code')}:</td>
            <td colSpan={2} className='td-cell-value'>
              {patient.code}
            </td>
          </tr>
          <tr>
            <td className='td-cell-label'>{t('label.sex')}:</td>
            <td className='td-cell-value'>{sex(patient.sex)}</td>
            <td className='td-patient-legend'>{t('label.male_female')}</td>
          </tr>
          <tr>
            <td className='td-cell-label'>{t('label.age')}:</td>
            <td className='td-cell-value'>{patient.age}</td>
            <td className='td-patient-legend'>[ 55 - 85 ]</td>
          </tr>
          <tr>
            <td className='td-cell-label'>CDR:</td>
            <td className='td-cell-value'>{patient.cdr}</td>
            <td className='td-patient-legend'>0 / 0.5 / 1 / 2 / 3</td>
          </tr>
          <tr>
            <td className='td-cell-label'>SOB:</td>
            <td className='td-cell-value'>{patient.sob}</td>
            <td className='td-patient-legend'>[ 0 - 18 ]</td>
          </tr>
          <tr>
            <td className='td-cell-label'>MMSE:</td>
            <td className='td-cell-value'>{patient.mmse !== null ? patient.mmse : t('label.not_available')}</td>
            <td className='td-patient-legend'>[ 0 - 30 ]</td>
          </tr>
          <tr>
            <td className='td-cell-label'>{t('label.apoe_genotype')}:</td>
            <td className='td-cell-value'>{patient.apoe}</td>
            <td className='td-patient-legend'>E2.E2 / E2.E3 / E2.E4 / E3.E3 / E3.E4 / E4.E4</td>
          </tr>
          <tr>
            <td className='td-cell-label'>A&beta; PET Scan:</td>
            <td className='td-cell-value'>{naPosNeg(patient.amyloidPet)}</td>
            <td className='td-patient-legend'>{t('label.pos_neg')}</td>
          </tr>
        </tbody>
      </table>
      {patient.neuroEval && (
        <table className='table-cell-output'>
          <tbody>
            <tr>
              <td className='td-cell-label'>{t('label.prob_ctrl')}:</td>
              <td className='td-patient-output'>{format(patient.probCtrl)} %</td>
            </tr>
            <tr>
              <td className='td-cell-label'>{t('label.prob_mci')}:</td>
              <td className='td-patient-output'>{format(patient.probMci)} %</td>
            </tr>
            <tr>
              <td className='td-cell-label'>{t('label.prob_ad')}:</td>
              <td className='td-patient-output'>{format(patient.probAd)} %</td>
            </tr>
            <tr>
              <td className='td-cell-label'>{t('label.neurological_conclusion')}:</td>
              {patient.neuroEval ? (
                <td className='td-patient-output'>{translate(patient.neuroEval)}</td>
              ) : (
                <td className='td-patient-output td-patient-pending'>{t('label.pending')}</td>
              )}
            </tr>
          </tbody>
        </table>
      )}

      {showEditButton && (
        <button type='button' onClick={editPatient}>
          {t('button.edit_this_patient')}
        </button>
      )}

      {patient.neuroEval && (
        <Link to={`/patient/report/${patient.id}`} target='_blank' rel='noopener noreferrer'>
          <button type='button'>{t('button.show_report')}</button>
        </Link>
      )}
    </div>
  )
}

export default PatientListCell
