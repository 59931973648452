import { Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormatter } from '../../hooks/useFormatter'
import { useModalContext } from '../../contexts/ModalContext'
import { host, uploadFile } from '../../functions/api'
import User from '../../models/User'

type Props = {
  user: User
  setUser: Dispatch<SetStateAction<User>>
  setCompleted: Dispatch<SetStateAction<boolean>>
}

/**
 * Subcomponent of UserMaster used to enter user data.
 */
const UserForm = ({ user, setUser, setCompleted }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { infoRole } = useFormatter()

  const { setModalMessage } = useModalContext()

  // Set user properties based on changes in form text fields.
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target
    setUser({ ...user, [id]: value })
  }

  // Set user properties based on changes in form radio buttons to select role.
  const handleRoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target
    if (!checked) return

    const role = id.split('-')[1]

    switch (role) {
      case 'client':
        setUser({
          ...user,
          role: role,
          medicalPosition: null,
          registrationNumber: null,
          medicalAssociation: null,
          signatureFile: null,
        })
        break
      case 'neuro':
        setUser({ ...user, role: role, code: null, purchasedRequests: null })
        break
      case 'admin':
        setUser({
          ...user,
          role: role,
          code: null,
          purchasedRequests: null,
          medicalPosition: null,
          registrationNumber: null,
          medicalAssociation: null,
          signatureFile: null,
        })
        break
    }
  }

  // Set state for signatureFile if user has selected a file.
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null) return

    const response = await uploadFile(e.target.files[0])

    if (response.data) {
      setUser({ ...user, signatureFile: response.data })
    } else {
      const error = response.error ? ` (Error ${response.error})` : ''
      setModalMessage(t('feedback.signature_not_uploaded') + error)
    }
  }

  // Inform that user data introduction has been completed.
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (user.code !== null) {
      user.code = user.code.toUpperCase()
    }

    if (user.role !== 'neuro') {
      setCompleted(true)
      return
    }

    if (user.signatureFile === null) {
      setModalMessage(t('feedback.signature_file_required'))
      return
    }

    setCompleted(true)
  }

  const roles = ['client', 'neuro', 'admin']

  return (
    <>
      <form onSubmit={onSubmit}>
        <div style={{ display: 'none' }}>{user.id}</div>

        <div className='form-box'>
          {/* Username */}
          <label htmlFor='username'>{t('label.username')}</label>
          <div>
            <input
              type='text'
              id='username'
              name='username'
              autoComplete='false'
              minLength={5}
              maxLength={25}
              value={user.username}
              onChange={handleChange}
              required
            />
            <span className='validation'>{t('label.req_info_5_25_chars')}</span>
          </div>

          {/* First name */}
          <label htmlFor='firstName'>{t('label.first_name')}</label>
          <div>
            <input
              type='text'
              id='firstName'
              name='firstName'
              minLength={2}
              maxLength={25}
              value={user.firstName}
              onChange={handleChange}
              required
            />
            <span className='validation'>{t('label.req_info_2_25_chars')}</span>
          </div>

          {/* Last name */}
          <label htmlFor='lastName'>{t('label.last_name')}</label>
          <div>
            <input
              type='text'
              id='lastName'
              name='lastName'
              minLength={2}
              maxLength={25}
              value={user.lastName}
              onChange={handleChange}
              required
            />
            <span className='validation'>{t('label.req_info_2_25_chars')}</span>
          </div>

          {/* Email */}
          <label htmlFor='email'>{t('label.email')}</label>
          <div>
            <input
              type='email'
              id='email'
              name='email'
              autoComplete='false'
              value={user.email}
              onChange={handleChange}
              required
            />
            <span className='validation'>{t('label.req_email')}</span>
          </div>

          {/* Role */}
          <p>{t('label.role')}</p>
          <div>
            <fieldset>
              <legend>{t('label.role')}</legend>
              {roles.map((role) => (
                <span key={role}>
                  <label htmlFor={`role-${role}`}>{infoRole(role)}</label>
                  <input
                    type='radio'
                    id={`role-${role}`}
                    name='role'
                    checked={user.role === role}
                    onChange={handleRoleChange}
                    required
                  />
                </span>
              ))}
            </fieldset>
            <span className={!user.role ? 'validation' : 'validated'}>{t('label.req_info')}</span>
          </div>

          {user.role === 'neuro' && (
            <>
              {/* Medical position (specific for neuro users) */}
              <label htmlFor='medicalPosition'>{t('label.medical_position')}</label>
              <div>
                <input
                  type='text'
                  id='medicalPosition'
                  name='medicalPosition'
                  minLength={5}
                  maxLength={25}
                  value={user.medicalPosition ?? ''}
                  onChange={handleChange}
                  required
                />
                <span className='validation'>{t('label.req_info_5_25_chars')}</span>
              </div>

              {/* Registration number (specific for neuro users) */}
              <label htmlFor='registrationNumber'>{t('label.registration_number')}</label>
              <div>
                <input
                  type='text'
                  id='registrationNumber'
                  name='registrationNumber'
                  minLength={2}
                  maxLength={15}
                  value={user.registrationNumber ?? ''}
                  onChange={handleChange}
                  required
                />
                <span className='validation'>{t('label.req_info_2_15_chars')}</span>
              </div>

              {/* Medical association (specific for neuro users) */}
              <label htmlFor='medicalAssociation'>{t('label.medical_association')}</label>
              <div>
                <input
                  type='text'
                  id='medicalAssociation'
                  name='medicalAssociation'
                  minLength={5}
                  maxLength={25}
                  value={user.medicalAssociation ?? ''}
                  onChange={handleChange}
                  required
                />
                <span className='validation'>{t('label.req_info_5_25_chars')}</span>
              </div>

              {/* Signature path (specific for neuro users) */}
              <label htmlFor='signatureFile'>{t('label.signature')}</label>
              <input
                type='file'
                id='signature'
                name='signature'
                accept='image/png, image/jpeg'
                onChange={handleFileChange}
              />
              {user.signatureFile && (
                <>
                  <p></p>
                  <img
                    src={`${host}d0138a9f-2962-4cef-93fc-733911b5d75e/${user.signatureFile}`}
                    alt={t('label.signature') ?? ''}
                    style={{ width: '10em' }}
                  />
                </>
              )}
            </>
          )}

          {user.role === 'client' && (
            <>
              {/* Code (specific for client users) */}
              <label htmlFor='code'>Code</label>
              <div>
                <input
                  type='text'
                  id='code'
                  name='code'
                  minLength={7}
                  maxLength={7}
                  pattern='^[a-zA-Z]{2}\d{5}$'
                  value={user.code ?? ''}
                  onChange={handleChange}
                  required
                />
                <span className='validation'>{t('label.req_info_7_chars')}</span>
                {/* <span className={!user.code || !codeMatchesRegex ? 'validation' : 'validated'}>
                  {t('label.req_info_7_chars')}
                </span> */}
              </div>

              {/* Purchased requests (specific for client users) */}
              <label htmlFor='purchasedRequests'>Purchased requests</label>
              <div>
                <input
                  type='number'
                  id='purchasedRequests'
                  name='purchasedRequests'
                  min='1'
                  max='100000'
                  value={user.purchasedRequests ?? ''}
                  onChange={handleChange}
                  required
                />
                <span className='validation'>{t('label.must_be_1_100000')}</span>
              </div>
            </>
          )}
          <div></div>
          <div>
            <button type='submit'>{t('button.save_changes')}</button>
            <button type='button' onClick={() => navigate('/user/list')}>
              {t('button.back_to_list')}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export default UserForm
