import { createContext, ReactNode, useState, useEffect, useContext } from 'react'
import { useDataContext } from './DataContext'
import { getAll } from '../functions/api'
import Translation from '../models/Translation'

type TranslationsContextType = {
  translations: Translation[]
}

type TranslationsProviderProps = {
  children: ReactNode
}

const TranslationsContext = createContext({} as TranslationsContextType)

const TranslationsProvider = ({ children }: TranslationsProviderProps) => {
  const [translations, setTranslations] = useState<Translation[]>([])
  const { currentUserId } = useDataContext()

  useEffect(() => {
    if (currentUserId) {
      const fetchTranslations = async () => {
        const response = await getAll<Translation>('patients/translations')
        if (response.status === 200) setTranslations(response.data)
      }

      fetchTranslations()
    }
  }, [currentUserId])

  return <TranslationsContext.Provider value={{ translations }}>{children}</TranslationsContext.Provider>
}

export const useTranslationsContext = () => useContext(TranslationsContext)

export default TranslationsProvider
