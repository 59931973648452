import { useTranslation } from 'react-i18next'
import { UserFilter } from '../../hooks/useUserFilter'
import Checkbox from '../UI/Checkbox'
import Fieldset from '../UI/Fieldset'

type Props = {
  filters: UserFilter
  toggle: (filter: string, checked: boolean) => void
  reset: () => void
}

const UserFilters = ({ filters, toggle, reset }: Props) => {
  const { t } = useTranslation()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    toggle(e.target.name, e.target.checked)
  }

  return (
    <>
      <div className='form-box' style={{ marginBottom: '2em' }}>
        <Fieldset title={t('label.role')}>
          <Checkbox id='roleClient' text={t('label.client')} checked={filters.roleClient} onChange={handleChange} />
          <Checkbox id='roleNeuro' text={t('label.neurologist')} checked={filters.roleNeuro} onChange={handleChange} />
          <Checkbox
            id='roleAdmin'
            text={t('label.administrator')}
            checked={filters.roleAdmin}
            onChange={handleChange}
          />
        </Fieldset>

        <p></p>
        <button type='button' onClick={reset}>
          {t('button.reset_filters')}
        </button>
      </div>
    </>
  )
}

export default UserFilters
