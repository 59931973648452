import { useTranslation } from 'react-i18next'
import { PatientFilter } from '../../hooks/usePatientFilter'
import Checkbox from '../UI/Checkbox'
import Fieldset from '../UI/Fieldset'

type Props = {
  filters: PatientFilter
  toggle: (filter: string, checked: boolean) => void
  reset: () => void
}

const PatientFilters = ({ filters, toggle, reset }: Props) => {
  const { t } = useTranslation()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    toggle(e.target.name, e.target.checked)
  }

  return (
    <div className='form-box' style={{ marginBottom: '2em' }}>
      <Fieldset title={t('label.sex')}>
        <Checkbox id='sexFemale' text={t('label.female')} checked={filters.sexFemale} onChange={handleChange} />
        <Checkbox id='sexMale' text={t('label.male')} checked={filters.sexMale} onChange={handleChange} />
      </Fieldset>

      <Fieldset title={t('label.age')}>
        <Checkbox id='age5560' text={t('label.between_55_60')} checked={filters.age5560} onChange={handleChange} />
        <Checkbox id='age6170' text={t('label.between_61_70')} checked={filters.age6170} onChange={handleChange} />
        <Checkbox id='age7180' text={t('label.between_71_80')} checked={filters.age7180} onChange={handleChange} />
        <Checkbox id='age8185' text={t('label.between_81_85')} checked={filters.age8185} onChange={handleChange} />
      </Fieldset>

      <Fieldset title='CDR'>
        <Checkbox id='cdr0' text='0' checked={filters.cdr0} onChange={handleChange} />
        <Checkbox id='cdr05' text='0.5' checked={filters.cdr05} onChange={handleChange} />
        <Checkbox id='cdr1' text='1' checked={filters.cdr1} onChange={handleChange} />
        <Checkbox id='cdr2' text='2' checked={filters.cdr2} onChange={handleChange} />
        <Checkbox id='cdr3' text='3' checked={filters.cdr3} onChange={handleChange} />
      </Fieldset>

      <Fieldset title='SOB'>
        <Checkbox id='sob06' text={t('label.between_0_6')} checked={filters.sob06} onChange={handleChange} />
        <Checkbox id='sob712' text={t('label.between_7_12')} checked={filters.sob712} onChange={handleChange} />
        <Checkbox id='sob1318' text={t('label.between_13_18')} checked={filters.sob1318} onChange={handleChange} />
      </Fieldset>

      <Fieldset title='MMSE'>
        <Checkbox id='mmse010' text={t('label.between_0_10')} checked={filters.mmse010} onChange={handleChange} />
        <Checkbox id='mmse1120' text={t('label.between_11_20')} checked={filters.mmse1120} onChange={handleChange} />
        <Checkbox id='mmse2130' text={t('label.between_21_30')} checked={filters.mmse2130} onChange={handleChange} />
        <Checkbox id='mmseNull' text={t('label.not_available')} checked={filters.mmseNull} onChange={handleChange} />
      </Fieldset>

      <Fieldset title='ApoE'>
        <Checkbox id='apoeE2e2' text='E2.E2' checked={filters.apoeE2e2} onChange={handleChange} />
        <Checkbox id='apoeE2e3' text='E2.E3' checked={filters.apoeE2e3} onChange={handleChange} />
        <Checkbox id='apoeE2e4' text='E2.E4' checked={filters.apoeE2e4} onChange={handleChange} />
        <Checkbox id='apoeE3e3' text='E3.E3' checked={filters.apoeE3e3} onChange={handleChange} />
        <Checkbox id='apoeE3e4' text='E3.E4' checked={filters.apoeE3e4} onChange={handleChange} />
        <Checkbox id='apoeE4e4' text='E4.E4' checked={filters.apoeE4e4} onChange={handleChange} />
      </Fieldset>

      <Fieldset title={t('label.pet')}>
        <Checkbox id='petNegative' text={t('label.negative')} checked={filters.petNegative} onChange={handleChange} />
        <Checkbox id='petPositive' text={t('label.positive')} checked={filters.petPositive} onChange={handleChange} />
        <Checkbox id='petNull' text={t('label.not_available')} checked={filters.petNull} onChange={handleChange} />
      </Fieldset>

      <Fieldset title={t('label.conclusion')}>
        <Checkbox
          id='conclusionWaiting'
          text={t('label.waiting_sample')}
          checked={filters.conclusionWaiting}
          onChange={handleChange}
        />
        <Checkbox
          id='conclusionPending'
          text={t('label.pending')}
          checked={filters.conclusionPending}
          onChange={handleChange}
        />
        <Checkbox
          id='conclusionCompleted'
          text={t('label.completed')}
          checked={filters.conclusionCompleted}
          onChange={handleChange}
        />
      </Fieldset>

      <p></p>
      <button type='button' onClick={reset}>
        {t('button.reset_filters')}
      </button>
    </div>
  )
}

export default PatientFilters
