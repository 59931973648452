import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const TempPassword = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const change = () => {
    navigate('/changepassword')
  }

  return (
    <>
      <h1>{t('label.action_required')}</h1>
      <p>{t('feedback.password_change_required')}</p>
      <br />
      <button type='button' onClick={change}>
        {t('button.change_password_now')}
      </button>
    </>
  )
}

export default TempPassword
