export const texts_ca = {
  button: {
    back_to_list: 'Tornar a la llista',
    close: 'Tancar',
    change_password_now: 'Canviar contrasenya ara',
    delete_patient: 'Esborrar pacient',
    delete_user: 'Esborrar usuari',
    donwload_report: 'Descarregar informe en PDF',
    edit: 'Editar',
    edit_this_patient: 'Editar aquest pacient',
    edit_this_user: 'Editar aquest usuari',
    list_view: 'Llista',
    hide_filters: 'Amagar filtres',
    no_cancel: 'No, cancel·lar',
    no_take_me_back: 'No, tornar enrere',
    report: 'Informe',
    reset_filters: 'Restablir filtres',
    reset_password: 'Restablir contrasenya',
    save_changes: 'Guardar canvis',
    show_filters: 'Mostrar filtres',
    show_report: 'Mostrar informe',
    table_view: 'Taula',
    yes_delete: 'Sí, esborrar',
    yes_reset_password: 'Sí, restablir contrasenya',
    yes_save_changes: 'Sí, guardar canvis',
  },
  nav: {
    change_password: 'Canviar contrasenya',
    home: 'Inici',
    legal: 'Informació legal',
    patient: {
      list: 'Veure tots els pacients',
      add: 'Afegir nou pacient',
    },
    user: {
      list: 'Veure tots els usuaris',
      add: 'Afegir nou usuari',
    },
  },
  login: {
    username: 'Usuari',
    password: 'Contrasenya',
    sign_in: 'Connectar',
    sign_out: 'Desconnectar',
    incorrect: 'Usuari o contrasenya incorrectes. Si us plau, torna a provar-ho.',
  },
  password: {
    current: 'Contrasenya actual',
    new1: 'Nova contrasenya',
    new2: 'Nova contrasenya (confirmació)',
    update: 'Actualitzar contrasenya',
    rule_no_empty:
      'Si us plau, ompli els tres camps. El primer amb la seva contrasenya actual i els dos següents amb la nova contrasenya que desitja.',
    rule_no_confirmation: 'La contrasenya nova i la contrasenya de confirmació no coincideixen.',
    rule_too_short: 'La nova contrasenya ha de tenir com a mínim 10 caràcters.',
    rule_no_upper: 'La nova contrasenya ha de tenir com a mínim una lletra en majúscula.',
    rule_no_lower: 'La nova contrasenya ha de tenir com a mínim una lletra en minúscula.',
    rule_no_number: 'La nova contrasenya ha de tenir com a mínim un número.',
    rule_no_special: 'La nova contrasenya ha de tenir com a mínim un caràcter especial.',
  },
  label: {
    accuracy: 'Precisió',
    action_required: 'Acció necessària',
    administrator: 'Administrador',
    age: 'Edat',
    amyloid_pet: 'PET Amiloide',
    apoe_genotype: 'Genotip ApoE',
    between_0_6: 'Entre 0 i 6',
    between_0_10: 'Entre 0 i 10',
    between_7_12: 'Entre 7 i 12',
    between_11_20: 'Entre 11 i 20',
    between_13_18: 'Entre 13 i 18',
    between_21_30: 'Entre 21 i 30',
    between_55_60: 'Entre 55 i 60',
    between_61_70: 'Entre 61 i 70',
    between_71_80: 'Entre 71 i 80',
    between_81_85: 'Entre 81 i 85',
    cdr_must_be_05: 'CDR ha de ser 0.5',
    cdr_values: '0 = No demència / 0.5 = Qüestionable / 1 = Lleu / 2 = Moderada / 3 = Greu',
    ci: 'IC',
    client: 'Client',
    code: 'Codi',
    completed: 'Completat',
    conclusion: 'Conclusió',
    created: 'Creat',
    e4_carrier_non: 'Portador E4 / No portador E4',
    edit_patient: 'Editar pacient',
    edit_user: 'Editar usuari',
    email: 'Email',
    female: 'Dona',
    first_name: 'Nom',
    full_name: 'Nom complet',
    interpretation: 'Interpretació',
    last_name: 'Cognoms',
    male: 'Home',
    male_female: 'Home / Dona',
    maintenance: 'MAP-AD Test està en manteniment en aquests moments. Si us plau, torna a provar-ho més tard.',
    medical_association: 'Associació mèdica',
    medical_position: 'Càrrec',
    methylcytosines:
      "L'ADN del pacient s'ha utilitzat al procés de laboratori MAP-AD Test Oligos, seguint el manual d'usuari. Les citosines mitocondrials metilades han sigut mesurades fent servir el MiSeq® System (Illumina) i analitzades pel MAP-AD Test Software per obtenir els resultats.",
    methodology: 'Metodologia',
    must_be_0_18: 'Ha de ser un número entre 0 i 18',
    must_be_0_30: 'Ha de ser un número entre 0 i 30',
    must_be_1_100000: 'Ha de ser un número entre 1 i 100000',
    must_be_55_85: 'Ha de ser un número entre 55 i 85',
    na: 'N/D',
    neurological_conclusion: 'Conclusió neurològica',
    neurologist: 'Neuròleg',
    new_patient: 'Nou pacient',
    new_user: 'Nou usuari',
    negative: 'Negatiu',
    not_available: 'No disponible',
    not_found: 'Pàgina no trobada (error 404)',
    password_change: 'Canvi de contrasenya',
    password_required_sign_report: "Contrasenya (necessària per a signar l'informe)",
    patient: 'Pacient',
    patients: 'Pacients',
    pending: 'Pendent',
    pet: 'PET Amiloide',
    positive: 'Positiu',
    pos_neg: 'Positiu / Negatiu',
    prob_ad: 'Probabilitat de MA',
    prob_ctrl: 'Probabilitat de Control',
    prob_mci: 'Probabilitat de DCL',
    prob_non_progressed: 'Probabilitat de No Progressió',
    prob_progressed: 'Probabilitat de Progressió',
    purchased_requests: 'Sol·licituds comprades',
    registration_number: 'Número de col·legiat',
    req_email: 'Adreça de correu electrònic vàlida necessària',
    req_info: 'Informació necessària',
    req_info_2_15_chars: 'Informació necessària (de 2 a 15 caràcters)',
    req_info_2_25_chars: 'Informació necessària (de 2 a 25 caràcters)',
    req_info_5_25_chars: 'Informació necessària (de 5 a 25 caràcters)',
    req_info_7_chars: 'Informació necessària (exactament 7 caràcters, format AB12345)',
    result: 'Resultat',
    role: 'Rol',
    select_conclusion: 'Selecciona una conclusió',
    sex: 'Sexe',
    signature: 'Signatura',
    unknown_role: 'Rol desconegut',
    username: 'Usuari',
    username_required_sign_report: "Usuari (necessari per a signar l'informe)",
    users: 'Usuaris',
    waiting_sample: "A l'espera de mostra",
  },
  feedback: {
    cdr_must_be_05: "Per tal de predir la probabilitat de progressió d'un pacient cal que el seu CDR sigui 0.5.",
    confirm_action: 'Si us plau, confirma aquesta acció',
    confirm_correct: 'Confirmes que és correcte?',
    delete_patient: 'Esborrar pacient {{code}} amb les següents característiques: {{info}}',
    delete_user: 'Esborrar usuari {{user}}',
    email_sent_user:
      "S'ha enviat un correu electrònic a l'adreça de correu electrònic de l'usuari amb aquesta informació. No necessites fer res més al respecte.",
    enter_conclusion: 'Si us plau, introdueix la teva conclusió neurològica.',
    no_patients_meeting_criteria: 'No hi ha pacients que compleixin aquests criteris.',
    no_users_meeting_criteria: 'No hi ha usuaris que compleixin aquests criteris.',
    not_logged_in: 'No has iniciat sessió o la teva sessió ha caducat. Si us plau, inicia sessió per continuar.',
    password_change_required:
      "Has de canviar la contrasenya temporal que ADmit Therapeutics t'ha proporcionat abans d'accedir a aquesta informació.",
    password_changed:
      "La teva contrsenya s'ha actualitzat. Si us plau, torna't a connectar fent servir la nova contrasenya.",
    password_not_changed:
      "La contrasenya no s'ha pogut actualitzar. Si us plau, verifica que la contrasenya és correcta i torna a provar-ho. Contacta amb ADmit Therapeutics si necessites ajuda.",
    password_not_reset:
      "La contrasenya no s'ha pogut restablir. Si us plau, torna a provar-ho més tard o contacta amb ADmit Therapeutics si necessites ajuda.",
    patient_deleted: "El pacient {{patient}} s'ha esborrat amb èxit.",
    patient_not_deleted:
      "No s'ha pogut esborrar el pacient. Si us plau, torna a provar-ho més tard o contacta amb ADmit Therapeutics si necessites ajuda.",
    patient_not_saved:
      "No s'ha pogut guardar el pacient. Si us plau, comprova que tots els camps es troben omplerts i torna a provar-ho. Si el problema persisteix, contacta amb ADmit Therapeutics per rebre ajuda.",
    patient_saved: 'Nou pacient creat amb el següent codi i aquestes característiques:',
    pdf_report_not_delivered:
      "No s'ha pogut descarregar l'informe en format PDF. Si us plau, torna a provar-ho més tard.",
    please_wait_request_processed: "Si us plau, espera mentre la teva petició s'està processant...",
    purchased_requests_exhausted:
      'No és possible crear aquest pacient perquè no et queden sol·licituds. Si us plau, contacta amb ADmit Therapeutics.',
    purchased_requests_info:
      '{{purchased}} sol·licituds comprades – {{created}} pacients creats – {{left}} sol·licituds restants',
    remember_code_patient: 'Recorda que pots consultar el codi de cada pacient al seu registre.',
    reset_password: "Restablir contrasenya per l'usuari {{user}}",
    select_option: 'Si us plau, procedeix a seleccionar la opció desitjada del menú.',
    session_expired:
      "La teva sessió ha caducat. Si us plau, torna't a connectar o contacta amb ADmit Therapeutics si has oblidat la teva contrasenya.",
    sign_report_auth_failed:
      'Autenticació fallida. Si us plau, introdueix un usuari i contrasenya correctes per a signar aquest informe.',
    signature_file_required: 'Per al rol de neuròleg escollit es necessita un arxiu que contigui la firma.',
    signature_not_uploaded:
      "L'arxiu que conté la signatura no s'ha pogut guardar al servidor. Si us plau, torna a provar-ho més tard o contacta amb ADmit Therapeutics si necessites ajuda.",
    user_deleted: "L'usuari {{user}} s'ha esborrat amb èxit.",
    user_not_deleted:
      "No s'ha pogut esborrar l'usuari. Si us plau, torna a provar-ho més tard o contacta amb ADmit Therapeutics si necessites ajuda.",
    user_not_saved:
      "No s'ha pogut guardar l'usuari. Si us plau, comprova que tots els camps es troben omplerts i torna a provar-ho. Si el problema persisteix, contacta amb ADmit Therapeutics per rebre ajuda.",
    user_not_saved_duplicated:
      "No s'ha pogut guardar l'usuari. Si us plau, comprova que no existeix un altre usuari amb el mateix nom d'usuari o adreça de correu electrònic.",
    user_saved: 'Usuari <strong>{{user}}</strong> creat/actualitzat amb una contrasenya temporal secreta.',
    welcome: 'Benvingut MAP-AD Test.',
    will_get_email_results: 'Rebràs un correu electrònic quan el resultat del pacient es trobi disponible.',
  },
  legal: {
    title: 'Informació legal',
    udi: '08437062887087',
    company: 'ADmit Therapeutics',
    focused:
      "Enfocat en el desenvolupament i comercialització d'una tecnologia novell per a la detecció primerenca de la Malaltia d'Alzheimer",
    description:
      "Aquest software és un dispositiu mèdic de diagnòstic in vitro (IVD) per al el pronòstic de la Demència per Malaltia d'Alzheimer (DMA) en pacients amb deteriorament cognitiu lleu (DCL) a través de l'anàlisi d'ADN extret de sang total i dades clíniques. Només per ús profesional.",
    version: 'Versió del software',
    serial: 'Número de sèrie',
    cookies:
      "Aquest lloc web fa servir les seves propies cookies i cookies de tercers per recolectar informació amb l'objectiu de millorar els nostres serveis. El seu ús continuat constitueix l'acceptació de l'instalació d'aquestes cookies. L'usuari té l'opció de configurar el navegador per a preveure la instal·lació de les cookies, pero això pot dificultar l'ús de la pàgina web.",
    warnings: 'Advertències',
    warnings_text_1: 'El MAP-AD Test Software és per a ús de diagnòstic in vitro.',
    warnings_text_2:
      'Aquest programari ha de ser utilitzat únicament per professionals de la salut o personal capacitat.',
    precautions: 'Precaucions',
    precautions_text1:
      "No desconnectis l'ordinador de la presa de corrent o el cable d'Internet, ja que no podràs utilitzar el programari.",
    precautions_text2:
      'Verifica la compatibilitat del teu sistema operatiu i navegador per assegurar-te que el programari pugui funcionar. Nota: JavaScript habilitat és un requisit.',
    precautions_text3:
      "Evita l'opció de completar automàticament l'usuari i la contrasenya al navegador, ja que una altra persona podria usar el teu usuari. Nota: En cas de pèrdua o oblit de l'usuari o de la contrasenya, posa't en contacte amb ADmit Therapeutics.",
    precautions_text4:
      'Evita distraccions quan introdueixis la informació en els camps obligatoris, una dada incorrecta potencialment resultarà en un resultat incorrecte per al pacient.',
    precautions_text5:
      "Evita distraccions en registrar l'identificador de la mostra o en identificar les mostres físiques, ja que el pacient podria rebre resultats creuats.",
    precautions_text6:
      "Evita les distraccions en sol·licitar la creació de l'usuari, ja que un correu electrònic incorrecte pot provocar que no es rebin actualitzacions del pacient.",
    precautions_text7:
      'Verifica que tens disponible el IFU per a consulta. ADmit Therapeutics et pot proporcionar el IFU sota petició si es necessari.',
    precautions_text8:
      "Verifica que l'usuari i contrasenya són correctes. ADmit Therapeutics et pot proporcionar una nova contrasenya sota demanda.",
  },
}
