import { useTranslationsContext } from '../contexts/TranslationsContext'
import { getLanguage } from '../functions/localStorage'

export const useNeuroTranslation = () => {
  const language = getLanguage() || 'en'
  const { translations } = useTranslationsContext()

  const translate = (neuroEval: number) => {
    const translation = translations.find((t) => t.id === neuroEval)
    if (!translation) return ''
    if (language === 'es') return translation.spanish
    if (language === 'ca') return translation.catalan
    return translation.english
  }

  const allTranslations = translations.map((t) => {
    if (language === 'es') return t.spanish
    if (language === 'ca') return t.catalan
    return t.english
  })

  return { translate, allTranslations }
}
