import { ReactNode } from 'react'

type Props = {
  title: string
  children: ReactNode
}

const Fieldset = ({ title, children }: Props) => {
  return (
    <>
      <p>{title}</p>
      <fieldset>
        <legend>{title}</legend>
        {children}
      </fieldset>
    </>
  )
}

export default Fieldset
