import { useTranslation } from 'react-i18next'
import MaintenanceData from '../models/MaintenanceData'

type Props = {
  data: MaintenanceData
}

const MaintenanceBanner = ({ data }: Props) => {
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language

  const showBanner = data.isScheduled ? '' : 'none'

  return (
    <div className={`maintenance ${showBanner}`}>
      {currentLanguage === 'ca' && data.messageCatalan}
      {currentLanguage === 'es' && data.messageSpanish}
      {currentLanguage === 'en' && data.messageEnglish}
    </div>
  )
}

export default MaintenanceBanner
