import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { texts_en } from './translations/english'
import { texts_ca } from './translations/catalan'
import { texts_es } from './translations/spanish'

// https://www.i18next.com/overview/configuration-options
i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: { translation: texts_en },
    ca: { translation: texts_ca },
    es: { translation: texts_es },
  },
})

export default i18n
