import { Dispatch, SetStateAction, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDataContext } from '../../contexts/DataContext'
import { useModalContext } from '../../contexts/ModalContext'
import { authenticate } from '../../functions/api'
import Patient from '../../models/Patient'
import PatientNeuroConclusion from './NeuroConclusion'

type Props = {
  patient: Patient
  setPatient: Dispatch<SetStateAction<Patient>>
  setCompleted: Dispatch<SetStateAction<boolean>>
}

/**
 * Subcomponent of PatientMaster used to enter patient data.
 */
const PatientForm = ({ patient, setPatient, setCompleted }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const { setModalMessage } = useModalContext()
  const { currentUserRole } = useDataContext()

  const parseBoolean = (value: string) => {
    if (value === 'true') return true
    if (value === 'false') return false
    return null
  }

  // Set patient properties based on changes in form text fields.
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target
    setPatient({ ...patient, [id]: value !== '' ? +value : null })
  }

  // Set patient Sex based on changes in form radio buttons.
  const handleSexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, name, checked } = e.target
    if (!checked) return
    setPatient({ ...patient, [name]: parseBoolean(id.split('-')[1]) })
  }

  // Set patient CDR based on changes in form radio buttons.
  const handleCdrChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target
    if (!checked) return
    setPatient({ ...patient, cdr: +id.split('-')[1] })
  }

  // Set patient SOB based on changes in form text field.
  const handleSobChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target
    const sob = parseFloat(value.replaceAll(',', '.'))
    if (isNaN(sob)) {
      setPatient({ ...patient, [id]: null })
    } else {
      setPatient({ ...patient, [id]: Math.round(+value * 10) / 10 })
    }
  }

  // Set patient ApoE based on changes in form radio buttons.
  const handleApoeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target
    if (!checked) return
    setPatient({ ...patient, apoe: id.split('-')[1] })
  }

  // Set patient Amyloid-PET based on changes in form radio buttons.
  const handleAmyloidPetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, name, checked } = e.target
    if (!checked) return
    setPatient({ ...patient, [name]: parseBoolean(id.split('-')[1]) })
  }

  // Set patient neurological conclusion based on changes in combobox.
  const handleNeuroConclusionChange = (option: number) => {
    setPatient({ ...patient, neuroEval: option })
  }

  // Inform that patient data introduction has been completed.
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (currentUserRole === 'client' && patient.cdr !== 0.5) {
      setModalMessage(t('feedback.cdr_must_be_05'))
      return
    }

    if (currentUserRole === 'neuro') {
      if (!patient.neuroEval) {
        setModalMessage(t('feedback.enter_conclusion'))
        return
      }

      const loginResponse = await authenticate({ username, password })
      if (!loginResponse) {
        setModalMessage(t('feedback.sign_report_auth_failed'))
        return
      }
    }

    setCompleted(true)
  }

  const apoes = ['E2.E2', 'E2.E3', 'E2.E4', 'E3.E3', 'E3.E4', 'E4.E4']
  const cdrs = [0, 0.5, 1, 2, 3]

  const disabled = currentUserRole === 'neuro' ? 'disabled' : undefined

  const cdrError = patient.cdr === -1 ? t('label.req_info') : patient.cdr !== 0.5 ? t('label.cdr_must_be_05') : ''

  return (
    <form onSubmit={onSubmit}>
      <div style={{ display: 'none' }}>{patient.id}</div>

      <div className='form-box'>
        {/* Code */}
        {patient.code && (
          <>
            <label htmlFor='code'>{t('label.code')}</label>
            <input type='text' id='code' name='code' disabled={true} value={patient.code} />
          </>
        )}

        {/* Sex */}
        <p>{t('label.sex')}</p>
        <div>
          <fieldset>
            <legend>{t('label.sex')}</legend>
            <label htmlFor='sex-false' className={disabled}>
              {t('label.female')}
            </label>
            <input
              type='radio'
              id='sex-false'
              name='sex'
              checked={patient.sex === false}
              onChange={handleSexChange}
              disabled={currentUserRole === 'neuro'}
              required
            />
            <label htmlFor='sex-true' className={disabled}>
              {t('label.male')}
            </label>
            <input
              type='radio'
              id='sex-true'
              name='sex'
              checked={patient.sex === true}
              onChange={handleSexChange}
              disabled={currentUserRole === 'neuro'}
              required
            />
          </fieldset>
          <span className={patient.sex === undefined ? 'validation' : 'validated'}>{t('label.req_info')}</span>
        </div>

        {/* Age */}
        <label htmlFor='age'>{t('label.age')}</label>
        <div>
          <input
            type='number'
            id='age'
            name='age'
            min='55'
            max='85'
            value={patient.age}
            onChange={handleChange}
            disabled={currentUserRole === 'neuro'}
            required
          />
          <span className='validation'>{t('label.must_be_55_85')}</span>
        </div>

        {/* CDR */}
        <p>CDR</p>
        <div>
          <fieldset>
            <legend>CDR</legend>
            {cdrs.map((cdr) => (
              <span key={cdr}>
                <label htmlFor={`cdr-${cdr}`} className={disabled}>
                  {cdr}
                </label>
                <input
                  type='radio'
                  id={`cdr-${cdr}`}
                  name='cdr'
                  checked={patient.cdr === cdr}
                  onChange={handleCdrChange}
                  disabled={currentUserRole === 'neuro'}
                  required
                />
              </span>
            ))}
          </fieldset>
          <span className={patient.cdr !== 0.5 ? 'validation' : 'validated'}>{cdrError}</span>
        </div>

        {/* SOB */}
        <label htmlFor='sob'>CDR-SOB</label>
        <div>
          <input
            type='number'
            id='sob'
            name='sob'
            step='0.1'
            min='0'
            max='18'
            value={patient.sob}
            onChange={handleSobChange}
            disabled={currentUserRole === 'neuro'}
            required
          />
          <span className='validation'>{t('label.must_be_0_18')}</span>
        </div>

        {/* MMSE */}
        <label htmlFor='mmse'>MMSE</label>
        <div>
          <input
            type='number'
            id='mmse'
            name='mmse'
            min='0'
            max='30'
            value={patient.mmse ?? ''}
            onChange={handleChange}
            disabled={currentUserRole === 'neuro'}
          />
          <span className='validation'>{t('label.must_be_0_30')}</span>
        </div>

        {/* ApoE */}
        <p>{t('label.apoe_genotype')}</p>
        <div>
          <fieldset>
            <legend>{t('label.apoe_genotype')}</legend>
            {apoes.map((apoe) => (
              <span key={apoe}>
                <label htmlFor={`apoe-${apoe}`} className={disabled}>
                  {apoe}
                </label>
                <input
                  type='radio'
                  id={`apoe-${apoe}`}
                  name='apoe'
                  checked={patient.apoe === apoe}
                  onChange={handleApoeChange}
                  disabled={currentUserRole === 'neuro'}
                  required
                />
              </span>
            ))}
          </fieldset>
          <span className={patient.apoe ? 'validated' : 'validation'}>{t('label.req_info')}</span>
        </div>

        {/* Amyloid PET */}
        <p>{t('label.amyloid_pet')}</p>
        <div>
          <fieldset>
            <legend>Amyloid PET</legend>
            <label htmlFor='amyloidPet-false' className={disabled}>
              {t('label.negative')}
            </label>
            <input
              type='radio'
              id='amyloidPet-false'
              name='amyloidPet'
              checked={patient.amyloidPet === false}
              onChange={handleAmyloidPetChange}
              disabled={currentUserRole === 'neuro'}
              required
            />
            <label htmlFor='amyloidPet-true' className={disabled}>
              {t('label.positive')}
            </label>
            <input
              type='radio'
              id='amyloidPet-true'
              name='amyloidPet'
              checked={patient.amyloidPet === true}
              onChange={handleAmyloidPetChange}
              disabled={currentUserRole === 'neuro'}
              required
            />
            <label htmlFor='amyloidPet-null' className={disabled}>
              {t('label.not_available')}
            </label>
            <input
              type='radio'
              id='amyloidPet-null'
              name='amyloidPet'
              checked={patient.amyloidPet === null}
              onChange={handleAmyloidPetChange}
              disabled={currentUserRole === 'neuro'}
              required
            />
          </fieldset>
          <span className={patient.amyloidPet === undefined ? 'validation' : 'validated'}>{t('label.req_info')}</span>
        </div>

        {/* Output probabilities */}
        {patient.probCtrl !== null && (
          <>
            <label htmlFor='probCtrl'>{t('label.prob_ctrl')}</label>
            <input
              type='text'
              id='probCtrl'
              name='probCtrl'
              disabled={true}
              value={`${((patient.probCtrl ?? 0) * 100).toFixed(1)} %`}
            />
          </>
        )}
        {patient.probMci !== null && (
          <>
            <label htmlFor='probMci'>{t('label.prob_mci')}</label>
            <input
              type='text'
              id='probMci'
              name='probMci'
              disabled={true}
              value={`${((patient.probMci ?? 0) * 100).toFixed(1)} %`}
            />
          </>
        )}
        {patient.probAd !== null && (
          <>
            <label htmlFor='probAd'>{t('label.prob_ad')}</label>
            <input
              type='text'
              id='probAd'
              name='probAd'
              disabled={true}
              value={`${((patient.probAd ?? 0) * 100).toFixed(1)} %`}
            />
          </>
        )}

        {/* Neurologist's evaluation */}
        {currentUserRole === 'neuro' && (
          <>
            <PatientNeuroConclusion onSelected={handleNeuroConclusionChange} />
            <label htmlFor='username'>{t('label.username_required_sign_report')}</label>
            <input
              type='text'
              id='username'
              autoComplete='false'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <label htmlFor='password'>{t('label.password_required_sign_report')}</label>
            <input type='password' id='password' value={password} onChange={(e) => setPassword(e.target.value)} />
          </>
        )}
        <div></div>
        <div>
          <button type='submit'>{t('button.save_changes')}</button>
          <button type='button' onClick={() => navigate('/patient/list')}>
            {t('button.back_to_list')}
          </button>
        </div>
      </div>
    </form>
  )
}

export default PatientForm
