import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNeuroTranslation } from '../../hooks/useNeuroTranslation'

type Props = {
  onSelected: (option: number) => void
}

const PatientNeuroConclusion = ({ onSelected }: Props) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(0)
  const { allTranslations } = useNeuroTranslation()

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelected(+event.target.value)
    onSelected(+event.target.value)
  }

  return (
    <>
      <label htmlFor='neuroEval'>{t('label.neurological_conclusion')}</label>
      <select onChange={handleChange} id='neuroEval' value={selected}>
        <option value={0}>{t('label.select_conclusion')}</option>
        {allTranslations.map((translation, index) => (
          <option key={index} value={index + 1}>
            {translation}
          </option>
        ))}
      </select>
    </>
  )
}

export default PatientNeuroConclusion
