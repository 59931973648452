import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDataContext } from '../../contexts/DataContext'
import { useFiltersContext } from '../../contexts/FiltersContext'
import { useModalContext } from '../../contexts/ModalContext'
import { useUserFilter } from '../../hooks/useUserFilter'
import UserFilters from './Filters'
import UserList from './List'
import UserTable from './Table'

const UserDirectory = () => {
  const { t } = useTranslation()

  const { usersTableView, setUsersTableView } = useFiltersContext()
  const { setExpired } = useModalContext()
  const { currentUserRole } = useDataContext()

  const [showFilters, setShowFilters] = useState(false)

  const { filters, toggle, reset, filteredUsers, apiError } = useUserFilter()

  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const users = filteredUsers()

  if (apiError === 401) setExpired(true)

  if (currentUserRole !== 'admin') return <></>

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2em' }}>
        <div>
          <button
            type='button'
            className={`${usersTableView ? 'btn-selected' : ''}`}
            onClick={() => setUsersTableView(true)}
          >
            {t('button.table_view')}
          </button>
          <button
            type='button'
            className={`${!usersTableView ? 'btn-selected' : ''}`}
            onClick={() => setUsersTableView(false)}
          >
            {t('button.list_view')}
          </button>
        </div>
        <div>
          <button type='button' onClick={toggleFilters}>
            {showFilters ? t('button.hide_filters') : t('button.show_filters')}
          </button>
        </div>
      </div>

      <div className={showFilters ? 'filters-show' : 'filters-hide'}>
        <UserFilters filters={filters} toggle={toggle} reset={reset} />
      </div>

      {users.length === 0 && <p className='no-results'>{t('feedback.no_users_meeting_criteria')}</p>}

      {users.length > 0 && usersTableView && <UserTable users={users} />}
      {users.length > 0 && !usersTableView && <UserList users={users} />}
    </>
  )
}

export default UserDirectory
