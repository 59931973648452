import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Restricted = () => {
  const { t } = useTranslation()

  return (
    <div>
      <Link to='/' className='relogin-required'>
        {t('feedback.not_logged_in')}
      </Link>
    </div>
  )
}

export default Restricted
