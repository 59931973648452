import { useDataContext } from '../contexts/DataContext'
import LoginResponse from '../models/LoginResponse'

export const useLogin = () => {
  const {
    setCurrentUserId,
    setCurrentUserRole,
    setCurrentUserFullName,
    setCurrentUserCode,
    setCurrentUserTemporaryPassword,
    setCurrentUserPurchasedRequests,
  } = useDataContext()

  const login = (loginResponse: LoginResponse) => {
    setCurrentUserId(loginResponse.id)
    setCurrentUserRole(loginResponse.role)
    setCurrentUserFullName(loginResponse.fullName)
    setCurrentUserCode(loginResponse.code)
    setCurrentUserTemporaryPassword(loginResponse.temporaryPassword)
    setCurrentUserPurchasedRequests(loginResponse.purchasedRequests)
  }

  return login
}
