const Header = () => {
  return (
    <header className='report'>
      <div className='header-report-left'>
        <div>
          <img src={require('../images/admitLogo.png')} alt='ADmit logo' />
        </div>
        <div>
          <p className='app-title'>MAP-AD Test</p>
        </div>
      </div>

      <div className='header-report-right'>
        <p className='report'>ADmit Therapeutics, S.L.</p>
        <p className='report'>Carrer Joan XXIII, 10</p>
        <p className='report'>08950 Esplugues de Llobregat</p>
        <p className='report'>Barcelona &ndash; Spain</p>
        <p className='report'>mapad@admit-therapeutics.com</p>
      </div>
    </header>
  )
}

export default Header
