import { useTranslation } from 'react-i18next'
import { useModalContext } from '../../contexts/ModalContext'
import '../../modal.css'

const ModalMessage = () => {
  const { t } = useTranslation()

  const { modalMessage, setModalMessage } = useModalContext()

  const close = () => {
    setModalMessage(null)
  }

  if (!modalMessage) return <></>

  return (
    <div className='modal-background'>
      <div className='modal-content'>
        <p>{modalMessage}</p>
        <br />
        <button type='button' onClick={close}>
          {t('button.close')}
        </button>
      </div>
    </div>
  )
}

export default ModalMessage
