import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePatientFilter } from '../../hooks/usePatientFilter'
import { useDataContext } from '../../contexts/DataContext'
import { useFiltersContext } from '../../contexts/FiltersContext'
import { useModalContext } from '../../contexts/ModalContext'
import PatientFilters from './Filters'
import PatientList from './List'
import PatientTable from './Table'

const PatientDirectory = () => {
  const { t } = useTranslation()

  const { patientsTableView, setPatientsTableView } = useFiltersContext()
  const { setExpired } = useModalContext()
  const { currentUserRole, currentUserPurchasedRequests } = useDataContext()

  const [showFilters, setShowFilters] = useState(false)

  const { filters, toggle, reset, filteredPatients, apiError } = usePatientFilter()

  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const patients = filteredPatients()

  if (apiError === 401) setExpired(true)

  // Information about purchased requests for clients.
  let purchasedRequestsInfo = <></>
  if (currentUserRole === 'client' && currentUserPurchasedRequests !== null) {
    const purchased = currentUserPurchasedRequests
    const created = patients.length
    const left = purchased - created
    purchasedRequestsInfo = (
      <p className='purchased-requests-info'>► {t('feedback.purchased_requests_info', { purchased, created, left })}</p>
    )
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2em' }}>
        <div>
          <button
            type='button'
            className={`${patientsTableView ? 'btn-selected' : ''}`}
            onClick={() => setPatientsTableView(true)}
          >
            {t('button.table_view')}
          </button>
          <button
            type='button'
            className={`${!patientsTableView ? 'btn-selected' : ''}`}
            onClick={() => setPatientsTableView(false)}
          >
            {t('button.list_view')}
          </button>
        </div>
        <div>
          <button type='button' onClick={toggleFilters}>
            {showFilters ? t('button.hide_filters') : t('button.show_filters')}
          </button>
        </div>
      </div>

      <div className={showFilters ? 'filters-show' : 'filters-hide'}>
        <PatientFilters filters={filters} toggle={toggle} reset={reset} />
      </div>

      {patients.length === 0 && <p className='no-results'>{t('feedback.no_patients_meeting_criteria')}</p>}

      {patients.length > 0 && patientsTableView && <PatientTable patients={patients} />}
      {patients.length > 0 && !patientsTableView && <PatientList patients={patients} />}

      {purchasedRequestsInfo}
    </>
  )
}

export default PatientDirectory
