import { useTranslation, Trans } from 'react-i18next'
import { useModalContext } from '../../contexts/ModalContext'
import '../../modal.css'

const ModalUserCreated = () => {
  const { t } = useTranslation()
  const { createdUser, setCreatedUser } = useModalContext()

  const close = () => {
    setCreatedUser(null)
  }

  if (!createdUser) return <></>

  return (
    <div className='modal-background'>
      <div className='modal-content'>
        <p>
          <Trans i18nKey='feedback.user_saved' values={{ user: createdUser }} />
        </p>
        <br />
        <p>{t('feedback.email_sent_user')}</p>
        <br />
        <button type='button' onClick={close}>
          {t('button.close')}
        </button>
      </div>
    </div>
  )
}

export default ModalUserCreated
