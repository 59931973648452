import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormatter } from '../../hooks/useFormatter'
import { useNeuroTranslation } from '../../hooks/useNeuroTranslation'
import { useDataContext } from '../../contexts/DataContext'
import Patient from '../../models/Patient'

type Props = {
  patient: Patient
}

const PatientTableRow = ({ patient }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { naPosNeg, sex } = useFormatter()
  const { currentUserRole } = useDataContext()
  const { translate } = useNeuroTranslation()

  const editPatient = () => {
    navigate(`/patient/${patient.id}`)
  }

  const showEditButton =
    (currentUserRole === 'client' && patient.probCtrl === null) ||
    (currentUserRole === 'neuro' && patient.probCtrl !== null && patient.neuroEval === null)

  const buttons = (
    <>
      {showEditButton && (
        <button type='button' onClick={editPatient}>
          {t('button.edit')}
        </button>
      )}
      {patient.neuroEval && (
        <Link to={`/patient/report/${patient.id}`} target='_blank' rel='noopener noreferrer'>
          <button type='button'>{t('button.report')}</button>
        </Link>
      )}
    </>
  )

  let tdNeuroEval = (
    <td className='td-flex td-patient-pending'>
      <div className='truncated'>{t('label.waiting_sample')}</div>
      <div>{buttons}</div>
    </td>
  )

  if (patient.probCtrl !== null) {
    tdNeuroEval = (
      <td className='td-flex td-patient-pending'>
        <div className='truncated'>{t('label.pending')}</div>
        <div>{buttons}</div>
      </td>
    )
  }

  if (patient.neuroEval !== null) {
    tdNeuroEval = (
      <td className='td-flex'>
        <div className='truncated'>{translate(patient.neuroEval)}</div>
        <div>{buttons}</div>
      </td>
    )
  }

  const format = (prob: number | null) => {
    if (prob === null) return '--'
    if (currentUserRole === 'client' && patient.neuroEval === null) return '--'
    return (prob * 100).toFixed(1)
  }

  return (
    <tr>
      <td>{patient.code}</td>
      <td>{sex(patient.sex)}</td>
      <td>{patient.age}</td>
      <td>{patient.cdr}</td>
      <td>{patient.sob}</td>
      <td>{patient.mmse !== null ? patient.mmse : '--'}</td>
      <td>{patient.apoe}</td>
      <td>{naPosNeg(patient.amyloidPet)}</td>
      <td>{format(patient.probCtrl)}</td>
      <td>{format(patient.probMci)}</td>
      <td>{format(patient.probAd)}</td>
      {tdNeuroEval}
    </tr>
  )
}

export default PatientTableRow
