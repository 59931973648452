import { useTranslation } from 'react-i18next'
import { useDataContext } from '../contexts/DataContext'
import TempPassword from '../components/TempPassword'
import PrecautionIcon from '../images/precaution.svg'
import WarningIcon from '../images/warning.svg'

const ScreensLegal = () => {
  const { t } = useTranslation()

  const { currentUserTemporaryPassword } = useDataContext()

  if (currentUserTemporaryPassword) return <TempPassword />

  return (
    <>
      <h1>{t('legal.title')}</h1>
      <div className='legal'>
        <h2>
          {t('legal.warnings')}
          <img src={WarningIcon} alt='Icon' />
        </h2>

        <p>{t('legal.warnings_text_1')}</p>
        <p>{t('legal.warnings_text_2')}</p>

        <h2>
          {t('legal.precautions')}
          <img src={PrecautionIcon} alt='Icon' />
        </h2>

        <p>{t('legal.precautions_text1')}</p>
        <p>{t('legal.precautions_text2')}</p>
        <p>{t('legal.precautions_text3')}</p>
        <p>{t('legal.precautions_text4')}</p>
        <p>{t('legal.precautions_text5')}</p>
        <p>{t('legal.precautions_text6')}</p>
        <p>{t('legal.precautions_text7')}</p>
        <p>{t('legal.precautions_text8')}</p>
      </div>
    </>
  )
}

export default ScreensLegal
