import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDataContext } from '../contexts/DataContext'
import { useModalContext } from '../contexts/ModalContext'
import { changePassword } from '../functions/api'

const ChangePasswordForm = () => {
  const { t } = useTranslation()
  const { setModalMessage, setPasswordChanged } = useModalContext()
  const { currentUserId } = useDataContext()
  const [passwords, setPasswords] = useState({
    oldPassword: '',
    newPassword1: '',
    newPassword2: '',
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target
    setPasswords({ ...passwords, [id]: value })
  }

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    // Check all fields are filled.
    if (passwords.oldPassword === '' || passwords.newPassword1 === '' || passwords.newPassword2 === '') {
      setModalMessage(t('password.rule_no_empty'))
      return
    }

    // Check new password and confirmation password match.
    if (passwords.newPassword1 !== passwords.newPassword2) {
      setModalMessage(t('password.rule_no_confirmation'))
      return
    }

    // Check new password is at least 10 characters long.
    if (passwords.newPassword1.length < 10) {
      setModalMessage(t('password.rule_too_short'))
      return
    }

    // Check new password includes an upper case letter.
    if (!/[A-Z]/.test(passwords.newPassword1)) {
      setModalMessage(t('password.rule_no_upper'))
      return
    }

    // Check new password includes a lower case letter.
    if (!/[a-z]/.test(passwords.newPassword1)) {
      setModalMessage(t('password.rule_no_lower'))
      return
    }

    // Check new password includes a number.
    if (!/[0-9]/.test(passwords.newPassword1)) {
      setModalMessage(t('password.rule_no_number'))
      return
    }

    // Check new password includes a special character.
    if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(passwords.newPassword1)) {
      setModalMessage(t('password.rule_no_special'))
      return
    }

    const response = await changePassword(currentUserId ?? '', passwords.oldPassword, passwords.newPassword1)

    if (response.success) {
      setPasswordChanged(true)
    } else {
      const error = response.error ? ` (Error ${response.error})` : ''
      setModalMessage(t('feedback.password_not_changed') + error)
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <div className='form-box'>
        <label htmlFor='oldPassword'>{t('password.current')}</label>
        <input
          type='password'
          id='oldPassword'
          name='oldPassword'
          value={passwords.oldPassword}
          onChange={handleChange}
        />
        <label htmlFor='newPassword1'>{t('password.new1')}</label>
        <input
          type='password'
          id='newPassword1'
          name='newPassword1'
          value={passwords.newPassword1}
          onChange={handleChange}
        />
        <label htmlFor='newPassword2'>{t('password.new2')}</label>
        <input
          type='password'
          id='newPassword2'
          name='newPassword2'
          value={passwords.newPassword2}
          onChange={handleChange}
        />{' '}
        <p></p>
        <button type='submit'>{t('password.update')}</button>
      </div>
    </form>
  )
}

export default ChangePasswordForm
