import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { saveLanguage } from '../functions/localStorage'
import NavBar from './NavBar'

const languages: Record<string, string> = {
  en: 'English',
  ca: 'Català',
  es: 'Español',
}

const Header = () => {
  const { i18n } = useTranslation()

  const onChangeLanguage = (language: string) => {
    saveLanguage(language)
    i18n.changeLanguage(language)
  }

  return (
    <header>
      <div className='header-left'>
        <div>
          {Object.keys(languages).map((language) => (
            <button
              key={language}
              className={i18n.resolvedLanguage === language ? 'btn-selected' : ''}
              type='submit'
              onClick={() => onChangeLanguage(language)}
            >
              {languages[language]}
            </button>
          ))}
        </div>
        <div>
          <Link to='/'>
            <img src={require('../images/admitLogo.png')} className='logo' alt='ADmit logo' />
          </Link>
        </div>
        <div>
          <Link to='/' className='app-title'>
            <p className='app-title'>MAP-AD Test</p>
          </Link>
        </div>
      </div>
      <div className='header-right'>
        <NavBar />
      </div>
    </header>
  )
}

export default Header
