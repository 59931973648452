import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDataContext } from '../contexts/DataContext'
import Restricted from '../components/Restricted'
import TempPassword from '../components/TempPassword'
import UserMaster from '../components/User/Master'

const PageUserForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()

  const { currentUserRole, currentUserTemporaryPassword } = useDataContext()

  if (currentUserTemporaryPassword) return <TempPassword />

  if (currentUserRole !== 'admin') navigate('/')

  return (
    <>
      <h1>{id ? t('label.edit_user') : t('label.new_user')}</h1>
      {currentUserRole === 'admin' ? <UserMaster id={id} /> : <Restricted />}
    </>
  )
}

export default PageUserForm
