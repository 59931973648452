import { createContext, ReactNode, useContext, useState } from 'react'

type DataContextType = {
  currentUserId: string | null
  setCurrentUserId: React.Dispatch<React.SetStateAction<string | null>>
  currentUserRole: string | null
  setCurrentUserRole: React.Dispatch<React.SetStateAction<string | null>>
  currentUserFullName: string | null
  setCurrentUserFullName: React.Dispatch<React.SetStateAction<string | null>>
  currentUserCode: string | null
  setCurrentUserCode: React.Dispatch<React.SetStateAction<string | null>>
  currentUserTemporaryPassword: boolean
  setCurrentUserTemporaryPassword: React.Dispatch<React.SetStateAction<boolean>>
  currentUserPurchasedRequests: number | null
  setCurrentUserPurchasedRequests: React.Dispatch<React.SetStateAction<number | null>>
}

type DataProviderProps = {
  children: ReactNode
}

const DataContext = createContext({} as DataContextType)

const DataProvider = ({ children }: DataProviderProps) => {
  const [currentUserId, setCurrentUserId] = useState<string | null>(null)
  const [currentUserRole, setCurrentUserRole] = useState<string | null>(null)
  const [currentUserFullName, setCurrentUserFullName] = useState<string | null>(null)
  const [currentUserCode, setCurrentUserCode] = useState<string | null>(null)
  const [currentUserTemporaryPassword, setCurrentUserTemporaryPassword] = useState(false)
  const [currentUserPurchasedRequests, setCurrentUserPurchasedRequests] = useState<number | null>(null)

  return (
    <DataContext.Provider
      value={{
        currentUserId,
        setCurrentUserId,
        currentUserRole,
        setCurrentUserRole,
        currentUserFullName,
        setCurrentUserFullName,
        currentUserCode,
        setCurrentUserCode,
        currentUserTemporaryPassword,
        setCurrentUserTemporaryPassword,
        currentUserPurchasedRequests,
        setCurrentUserPurchasedRequests,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

export const useDataContext = () => useContext(DataContext)

export default DataProvider
