import { useTranslation } from 'react-i18next'
import { useModalContext } from '../../contexts/ModalContext'
import '../../modal.css'

const ModalSpinner = () => {
  const { t } = useTranslation()

  const { spinner } = useModalContext()

  if (!spinner) return <></>

  return (
    <div className='modal-background'>
      <div className='modal-content'>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <p>{t('feedback.please_wait_request_processed')}</p>
          <br />
          <div className='lds-hourglass'></div>
        </div>
      </div>
    </div>
  )
}

export default ModalSpinner
