import { useTranslation } from 'react-i18next'
import SectionHeader from './SectionHeader'

type Props = {
  text: string
}

const Methodology = ({ text }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <SectionHeader title={t('label.methodology')} highlight={false} />
      <p>{text}</p>
    </>
  )
}

export default Methodology
