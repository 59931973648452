import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDataContext } from '../contexts/DataContext'
import PatientMaster from '../components/Patient/Master'
import Restricted from '../components/Restricted'
import TempPassword from '../components/TempPassword'

const PagePatientForm = () => {
  const { t } = useTranslation()

  const { id } = useParams()

  const { currentUserId, currentUserTemporaryPassword } = useDataContext()

  if (currentUserTemporaryPassword) return <TempPassword />

  return (
    <>
      <h1>{id ? t('label.edit_patient') : t('label.new_patient')}</h1>
      {currentUserId ? <PatientMaster id={id} /> : <Restricted />}
    </>
  )
}

export default PagePatientForm
