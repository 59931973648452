import { createContext, ReactNode, useContext, useState } from 'react'

type FiltersContextType = {
  patientsTableView: boolean
  setPatientsTableView: React.Dispatch<React.SetStateAction<boolean>>
  usersTableView: boolean
  setUsersTableView: React.Dispatch<React.SetStateAction<boolean>>
}

type FiltersProviderProps = {
  children: ReactNode
}

const FiltersContext = createContext({} as FiltersContextType)

const FiltersProvider = ({ children }: FiltersProviderProps) => {
  const [patientsTableView, setPatientsTableView] = useState(true)
  const [usersTableView, setUsersTableView] = useState(true)

  return (
    <FiltersContext.Provider
      value={{
        patientsTableView,
        setPatientsTableView,
        usersTableView,
        setUsersTableView,
      }}
    >
      {children}
    </FiltersContext.Provider>
  )
}

export const useFiltersContext = () => useContext(FiltersContext)

export default FiltersProvider
