import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDataContext } from '../contexts/DataContext'
import Restricted from '../components/Restricted'
import TempPassword from '../components/TempPassword'
import UserDirectory from '../components/User/Directory'
import UsersIcon from '../images/users.svg'

const PageUserList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { currentUserRole, currentUserTemporaryPassword } = useDataContext()

  if (currentUserTemporaryPassword) return <TempPassword />

  if (currentUserRole !== 'admin') navigate('/')

  return (
    <>
      <h1>
        {t('label.users')}
        <img src={UsersIcon} alt='DNA icon' />
      </h1>
      {currentUserRole === 'admin' ? <UserDirectory /> : <Restricted />}
    </>
  )
}

export default PageUserList
