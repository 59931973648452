import { createContext, ReactNode, useContext, useState } from 'react'

type ModalContextType = {
  expired: boolean
  setExpired: React.Dispatch<React.SetStateAction<boolean>>
  passwordChanged: boolean
  setPasswordChanged: React.Dispatch<React.SetStateAction<boolean>>
  createdUser: string | null
  setCreatedUser: React.Dispatch<React.SetStateAction<string | null>>
  createdPatient: string | null
  setCreatedPatient: React.Dispatch<React.SetStateAction<string | null>>
  createdCode: string | null
  setCreatedCode: React.Dispatch<React.SetStateAction<string | null>>
  modalMessage: string | null
  setModalMessage: React.Dispatch<React.SetStateAction<string | null>>
  spinner: boolean
  setSpinner: React.Dispatch<React.SetStateAction<boolean>>
}

type ModalProviderProps = {
  children: ReactNode
}

const ModalContext = createContext({} as ModalContextType)

const ModalProvider = ({ children }: ModalProviderProps) => {
  const [expired, setExpired] = useState(false)
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [createdUser, setCreatedUser] = useState<string | null>(null)
  const [createdPatient, setCreatedPatient] = useState<string | null>(null)
  const [createdCode, setCreatedCode] = useState<string | null>(null)
  const [modalMessage, setModalMessage] = useState<string | null>(null)
  const [spinner, setSpinner] = useState(false)

  return (
    <ModalContext.Provider
      value={{
        expired,
        setExpired,
        passwordChanged,
        setPasswordChanged,
        createdUser,
        setCreatedUser,
        createdPatient,
        setCreatedPatient,
        createdCode,
        setCreatedCode,
        modalMessage,
        setModalMessage,
        spinner,
        setSpinner,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export const useModalContext = () => useContext(ModalContext)

export default ModalProvider
