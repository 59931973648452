export const texts_en = {
  button: {
    back_to_list: 'Back to list',
    close: 'Close',
    change_password_now: 'Change password now',
    delete_patient: 'Delete patient',
    delete_user: 'Delete user',
    donwload_report: 'Download report as PDF',
    edit: 'Edit',
    edit_this_patient: 'Edit this patient',
    edit_this_user: 'Edit this user',
    list_view: 'List view',
    hide_filters: 'Hide filters',
    no_cancel: 'No, cancel',
    no_take_me_back: 'No, take me back',
    report: 'Report',
    reset_filters: 'Reset filters',
    reset_password: 'Reset password',
    save_changes: 'Save changes',
    show_filters: 'Show filters',
    show_report: 'Show report',
    table_view: 'Table view',
    yes_delete: 'Yes, delete',
    yes_reset_password: 'Yes, reset password',
    yes_save_changes: 'Yes, save changes',
  },
  nav: {
    change_password: 'Change password',
    home: 'Home',
    legal: 'Legal information',
    patient: {
      list: 'See all patients',
      add: 'Add new patient',
    },
    user: {
      list: 'See all users',
      add: 'Add new user',
    },
  },
  login: {
    username: 'Username',
    password: 'Password',
    sign_in: 'Sign in',
    sign_out: 'Sign out',
    incorrect: 'Incorrect user or password. Please try again.',
  },
  password: {
    current: 'Current password',
    new1: 'New password',
    new2: 'New password (confirmation)',
    update: 'Update password',
    rule_no_empty:
      'Please fill all three fields with your current password (once) and the new password you want to use (twice).',
    rule_no_confirmation: 'New password and confirmation password do not match.',
    rule_too_short: 'New password must be at least 10 characters long.',
    rule_no_upper: 'New password must include at least one upper case letter.',
    rule_no_lower: 'New password must include at least one lower case letter.',
    rule_no_number: 'New password must include at least one number.',
    rule_no_special: 'New password must include at least one special character.',
  },
  label: {
    accuracy: 'Accuracy',
    action_required: 'Action required',
    administrator: 'Administrator',
    age: 'Age',
    amyloid_pet: 'Amyloid PET',
    apoe_genotype: 'ApoE genotype',
    between_0_6: 'Between 0 and 6',
    between_0_10: 'Between 0 and 10',
    between_7_12: 'Between 7 and 12',
    between_11_20: 'Between 11 and 20',
    between_13_18: 'Between 13 and 18',
    between_21_30: 'Between 21 and 30',
    between_55_60: 'Between 55 and 60',
    between_61_70: 'Between 61 and 70',
    between_71_80: 'Between 71 and 80',
    between_81_85: 'Between 81 and 85',
    cdr_must_be_05: 'CDR must be 0.5',
    cdr_values: '0 = No dementia / 0.5 = Questionable / 1 = Mild / 2 = Moderate / 3 = Severe',
    ci: 'CI',
    client: 'Client',
    code: 'Code',
    completed: 'Completed',
    conclusion: 'Conclusion',
    created: 'Created',
    e4_carrier_non: 'E4 carrier / Non E4 carrier',
    edit_patient: 'Edit patient',
    edit_user: 'Edit user',
    email: 'Email',
    female: 'Female',
    first_name: 'First name',
    full_name: 'Full name',
    interpretation: 'Interpretation',
    last_name: 'Last name',
    male: 'Male',
    male_female: 'Male / Female',
    maintenance: 'MAP-AD Test is currently under maintenance. Please try again later.',
    medical_association: 'Medical association',
    medical_position: 'Medical position',
    methylcytosines:
      "The patient's DNA has been used in the MAP-AD Test Oligos laboratory process following its user manual. The mitochondrial methylcytosines have been measured using the MiSeq® System (Illumina) and analysed by the MAP-AD Test Software to obtain the results.",
    methodology: 'Methodology',
    must_be_0_18: 'Must be a number from 0 to 18',
    must_be_0_30: 'Must be a number from 0 to 30',
    must_be_1_100000: 'Must be a number from 1 to 100000',
    must_be_55_85: 'Must be a number from 55 to 85',
    na: 'N/A',
    neurological_conclusion: 'Neurological conclusion',
    neurologist: 'Neurologist',
    new_patient: 'New patient',
    new_user: 'New user',
    negative: 'Negative',
    not_available: 'Not available',
    not_found: 'Page not found (error 404)',
    password_change: 'Password change',
    password_required_sign_report: 'Password (required to sign the report)',
    patient: 'Patient',
    patients: 'Patients',
    pending: 'Pending',
    pet: 'Amyloid PET',
    positive: 'Positive',
    pos_neg: 'Positive / Negative',
    prob_ad: 'Probability of AD',
    prob_ctrl: 'Probability of Control',
    prob_mci: 'Probability of MCI',
    prob_non_progressed: 'Probability of Non Progressed',
    prob_progressed: 'Probability of Progressed',
    purchased_requests: 'Purchased requests',
    registration_number: 'Registration number',
    req_email: 'Required valid email address',
    req_info: 'Required information',
    req_info_2_15_chars: 'Required information (2 to 15 characters)',
    req_info_2_25_chars: 'Required information (2 to 25 characters)',
    req_info_5_25_chars: 'Required information (5 to 25 characters)',
    req_info_7_chars: 'Required information (exactly 7 characters, ensure format is AB12345)',
    result: 'Result',
    role: 'Role',
    select_conclusion: 'Select a conclusion',
    sex: 'Sex',
    signature: 'Signature',
    unknown_role: 'Unknown role',
    username: 'Username',
    username_required_sign_report: 'Username (required to sign the report)',
    users: 'Users',
    waiting_sample: 'Waiting for sample',
  },
  feedback: {
    cdr_must_be_05: 'In order to predict the probability of progression for a patient, their CDR must be 0.5.',
    confirm_action: 'Please confirm this action',
    confirm_correct: 'Do you confirm this is correct?',
    delete_patient: 'Delete patient {{code}} with these characteristics: {{info}}',
    delete_user: 'Delete user {{user}}',
    email_sent_user:
      'An email has already been sent to their email address with this information. You do not need to do anything else on this regard.',
    enter_conclusion: 'Please enter your neurological conclusion.',
    no_patients_meeting_criteria: 'There are no patients meeting these criteria.',
    no_users_meeting_criteria: 'There are no users meeting these criteria.',
    not_logged_in: 'You are not logged in or your session has expired. Please sign in to continue.',
    password_change_required:
      'You need to change the temporary password ADmit Therapeutics provided you before you can access this information.',
    password_changed: 'Your password has been changed. Please sign in again using your new password.',
    password_not_changed:
      'Your password could not be changed. Please make sure the current password is correct and try again. Contact ADmit Therapeutics if you need some help.',
    password_not_reset:
      'Password could not be reset. Please try again later or contact ADmit Therapeutics if you need some help.',
    patient_deleted: 'Patient {{patient}} successfully deleted.',
    patient_not_deleted:
      'Patient could not be deleted. Please try again later or contact ADmit Therapeutics if you need some help.',
    patient_not_saved:
      'Patient could not be saved. Please check all fields are informed and try again. If problem persists, contact ADmit Therapeutics for assistance.',
    patient_saved: 'New patient has been created with these code and characteristics:',
    pdf_report_not_delivered: 'The report could not be downloaded as a PDF. Please try again later.',
    please_wait_request_processed: 'Please wait a moment while your request is processed...',
    purchased_requests_exhausted:
      'Patient could not be created because you have exhausted all your purchased requests. Please contact ADmit Therapeutics.',
    purchased_requests_info: '{{purchased}} purchased requests – {{created}} patients created – {{left}} requests left',
    remember_code_patient: 'Remember that you can see the code of each patient in their record.',
    reset_password: 'Reset password for user {{user}}',
    select_option: 'Please proceed to select the desired option from the menu.',
    session_expired:
      'Your session has expired. Please sign in again or contact ADmit Therapeutics if you cannot remember your password.',
    sign_report_auth_failed:
      'Authentication failed. Please enter your correct username and password to sign this report.',
    signature_file_required: 'Signature file is required for a user with neuro role selected.',
    signature_not_uploaded:
      'Signature file could not be uploaded to the server. Please try again later or contact ADmit Therapeutics if the problem persists.',
    user_deleted: 'User {{user}} successfully deleted.',
    user_not_deleted:
      'User could not be deleted. Please try again later or contact ADmit Therapeutics if you need some help.',
    user_not_saved:
      'User could not be saved. Please check all fields are informed and try again. If problem persists, contact ADmit Therapeutics for assistance.',
    user_not_saved_duplicated:
      'User could not be saved. Please double check a user with this username or email address has not already been created.',
    user_saved: 'User <strong>{{user}}</strong> has been created/updated with a secret temporary password.',
    welcome: 'Welcome to MAP-AD Test.',
    will_get_email_results: 'You will receive an email when final result for this patient is available.',
  },
  legal: {
    title: 'Legal information',
    udi: '08437062887063',
    company: 'ADmit Therapeutics',
    focused:
      "Focused on the development and commercialization of a novel Alzheimer's disease (AD) early detection technology",
    description:
      "This software is an in vitro diagnostic medical device for the prognosis of Alzheimer's Disease Dementia (ADD) in Mild Cognitive Impairment (MCI) patients by analyzing total DNA extracted from human whole blood and clinical data. For professional use only.",
    version: 'Software version',
    serial: 'Serial number',
    cookies:
      'This website uses our own cookies and third-party cookies to collect information, in order to improve our services. Your continued use of this website constitutes acceptance of the installation of these cookies. The user has the option of configuring their browser in order to prevent cookies from being installed on their hard drive, although they must keep in mind that doing so may cause difficulties in their use of the webpage.',
    warnings: 'Warnings',
    warnings_text_1: 'The MAP-AD Test Software is for in vitro diagnostic use.',
    warnings_text_2: 'This software needs to be used by healthcare professionals or trained personnel only.',
    precautions: 'Precautions',
    precautions_text1: 'Do not disconnect the PC power or internet wire as you would not be able to use the software.',
    precautions_text2:
      'Check the compatibility your operating system and browser to ensure the software is able to work. Note: JavaScript enabled is a requirement.',
    precautions_text3:
      'Avoid auto filling the user and password in the browser as other person might use your user. Note: If the user or the password is lost or forgotten, please, contact ADmit Therapeutics.',
    precautions_text4:
      'Avoid distractions while introducing the information in the required fields, an incorrect data will potentially result in an incorrect result to the patient.',
    precautions_text5:
      'Avoid distractions while recording the sample identifier or while identifying the physical samples as the patient might receive crossed results.',
    precautions_text6:
      'Avoid distractions while requesting the user creation as an incorrect email might result in no patient update receival.',
    precautions_text7:
      'Ensure the IFU is available for its checking. ADmit Therapeutics can provide the IFU upon request if necessary.',
    precautions_text8:
      'Ensure the login username and password are correct. ADmit Therapeutics can provide a new password under request.',
  },
}
