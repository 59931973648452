import { useEffect, useState } from 'react'
import { getAll } from '../functions/api'
import Patient from '../models/Patient'

export class PatientFilter {
  sexFemale: boolean = true
  sexMale: boolean = true
  age5560: boolean = true
  age6170: boolean = true
  age7180: boolean = true
  age8185: boolean = true
  cdr0: boolean = true
  cdr05: boolean = true
  cdr1: boolean = true
  cdr2: boolean = true
  cdr3: boolean = true
  sob06: boolean = true
  sob712: boolean = true
  sob1318: boolean = true
  mmse010: boolean = true
  mmse1120: boolean = true
  mmse2130: boolean = true
  mmseNull: boolean = true
  apoeE2e2: boolean = true
  apoeE2e3: boolean = true
  apoeE2e4: boolean = true
  apoeE3e3: boolean = true
  apoeE3e4: boolean = true
  apoeE4e4: boolean = true
  petNegative: boolean = true
  petPositive: boolean = true
  petNull: boolean = true
  conclusionWaiting: boolean = true
  conclusionPending: boolean = true
  conclusionCompleted: boolean = true
}

export const usePatientFilter = () => {
  const [patients, setPatients] = useState<Patient[]>([])
  const [apiError, setApiError] = useState(0)
  const [filters, setFilters] = useState(new PatientFilter())

  useEffect(() => {
    const fetchPatients = async () => {
      const response = await getAll<Patient>('patients/')
      if (response.status === 401) setApiError(401)
      if (response.status === 200) setPatients(response.data)
    }

    fetchPatients()
  }, [])

  const toggle = (filter: string, checked: boolean) => {
    // Only allow the toggle if at least one filter is checked after the toggle.
    if (!checked) {
      const group = filter.split(/(?=[A-Z]|\d)/)[0]
      const totalChecked = Object.entries(filters).filter(([key, value]) => key.startsWith(group) && value).length
      if (totalChecked <= 1) return
    }

    setFilters({ ...filters, [filter]: checked })
  }

  const reset = () => {
    setFilters(new PatientFilter())
  }

  const filteredPatients = () => {
    return patients.filter((patient) => {
      if (!filters.sexMale && patient.sex) return false
      if (!filters.sexFemale && !patient.sex) return false
      if (!filters.age5560 && patient.age <= 60) return false
      if (!filters.age6170 && patient.age >= 61 && patient.age <= 70) return false
      if (!filters.age7180 && patient.age >= 71 && patient.age <= 80) return false
      if (!filters.age8185 && patient.age >= 81) return false
      if (!filters.cdr0 && patient.cdr === 0) return false
      if (!filters.cdr05 && patient.cdr === 0.5) return false
      if (!filters.cdr1 && patient.cdr === 1) return false
      if (!filters.cdr2 && patient.cdr === 2) return false
      if (!filters.cdr3 && patient.cdr === 3) return false
      if (!filters.sob06 && patient.sob <= 6) return false
      if (!filters.sob712 && patient.sob >= 7 && patient.sob <= 12) return false
      if (!filters.sob1318 && patient.sob >= 13) return false
      if (!filters.mmse010 && patient.mmse !== null && patient.mmse <= 10) return false
      if (!filters.mmse1120 && patient.mmse !== null && patient.mmse >= 11 && patient.mmse <= 20) return false
      if (!filters.mmse2130 && patient.mmse !== null && patient.mmse >= 21) return false
      if (!filters.mmseNull && patient.mmse === null) return false
      if (!filters.apoeE2e2 && patient.apoe === 'E2.E2') return false
      if (!filters.apoeE2e3 && patient.apoe === 'E2.E3') return false
      if (!filters.apoeE2e4 && patient.apoe === 'E2.E4') return false
      if (!filters.apoeE3e3 && patient.apoe === 'E3.E3') return false
      if (!filters.apoeE3e4 && patient.apoe === 'E3.E4') return false
      if (!filters.apoeE4e4 && patient.apoe === 'E4.E4') return false
      if (!filters.petNegative && patient.amyloidPet !== null && !patient.amyloidPet) return false
      if (!filters.petPositive && patient.amyloidPet !== null && patient.amyloidPet) return false
      if (!filters.petNull && patient.amyloidPet === null) return false
      if (!filters.conclusionWaiting && patient.probCtrl === null) return false
      if (!filters.conclusionPending && patient.probCtrl !== null && patient.neuroEval === null) return false
      if (!filters.conclusionCompleted && patient.neuroEval !== null) return false

      return true
    })
  }

  return { filters, apiError, toggle, reset, filteredPatients }
}
