import { useTranslation } from 'react-i18next'
import { useConfirmationContext } from '../../contexts/ConfirmationContext'
import '../../modal.css'

const ModalDeleteConfirm = () => {
  const { t } = useTranslation()

  const { deleteConfirmationText, setDeleteConfirmationText, setDeleteConfirmed } = useConfirmationContext()

  const confirm = () => {
    setDeleteConfirmed(true)
    setDeleteConfirmationText(null)
  }

  const cancel = () => {
    setDeleteConfirmationText(null)
  }

  if (!deleteConfirmationText) return <></>

  return (
    <div className='modal-background'>
      <div className='modal-content'>
        <p className='h4'>{t('feedback.confirm_action')}</p>
        <p>{deleteConfirmationText}</p>
        <br />
        <button type='button' className='btn-danger' style={{ float: 'right' }} onClick={confirm}>
          {t('button.yes_delete')}
        </button>
        <button type='button' onClick={cancel}>
          {t('button.no_cancel')}
        </button>
      </div>
    </div>
  )
}

export default ModalDeleteConfirm
