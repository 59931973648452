export const texts_es = {
  button: {
    back_to_list: 'Volver a la lista',
    close: 'Cerrar',
    change_password_now: 'Cambiar contraseña ahora',
    delete_patient: 'Borrar paciente',
    delete_user: 'Borrar usuario',
    donwload_report: 'Descargar informe en PDF',
    edit: 'Editar',
    edit_this_patient: 'Editar este paciente',
    edit_this_user: 'Editar este usuario',
    list_view: 'Lista',
    hide_filters: 'Ocultar filtros',
    no_cancel: 'No, cancelar',
    no_take_me_back: 'No, volver atrás',
    report: 'Informe',
    reset_filters: 'Restablecer filtros',
    reset_password: 'Restablecer contraseña',
    save_changes: 'Guardar cambios',
    show_filters: 'Mostrar filtros',
    show_report: 'Mostrar informe',
    table_view: 'Tabla',
    yes_delete: 'Sí, borrar',
    yes_reset_password: 'Sí, restablecer contraseña',
    yes_save_changes: 'Sí, guardar cambios',
  },
  nav: {
    change_password: 'Cambiar contraseña',
    home: 'Inicio',
    legal: 'Información legal',
    patient: {
      list: 'Ver todos los pacientes',
      add: 'Añadir nuevo paciente',
    },
    user: {
      list: 'Ver todos los usuarios',
      add: 'Añadir nuevo usuario',
    },
  },
  login: {
    username: 'Usuario',
    password: 'Contraseña',
    sign_in: 'Conectar',
    sign_out: 'Desconectar',
    incorrect: 'Usuario o contraseña incorrectos. Por favor, inténtelo de nuevo.',
  },
  password: {
    current: 'Contraseña actual',
    new1: 'Nueva contraseña',
    new2: 'Nueva contraseña (confirmación)',
    update: 'Actualizar contraseña',
    rule_no_empty:
      'Por favor, rellene los tres campos. El primero con su contraseña actual y los dos siguientes con la nueva contraseña que desea.',
    rule_no_confirmation: 'La nueva contraseña y la contraseña de confirmación no coinciden.',
    rule_too_short: 'La nueva contraseña debe tener al menos 10 caracteres.',
    rule_no_upper: 'La nueva contraseña debe incluir al menos una letra en mayúscula.',
    rule_no_lower: 'La nueva contraseña debe incluir al menos una letra en minúscula.',
    rule_no_number: 'La nueva contraseña debe incluir al menos un número.',
    rule_no_special: 'La nueva contraseña debe incluir al menos un carácter especial.',
  },
  label: {
    accuracy: 'Precisión',
    action_required: 'Acción necesaria',
    administrator: 'Administrador',
    age: 'Edad',
    amyloid_pet: 'PET Amiloide',
    apoe_genotype: 'Genotipo ApoE',
    between_0_6: 'Entre 0 y 6',
    between_0_10: 'Entre 0 y 10',
    between_7_12: 'Entre 7 y 12',
    between_11_20: 'Entre 11 y 20',
    between_13_18: 'Entre 13 y 18',
    between_21_30: 'Entre 21 y 30',
    between_55_60: 'Entre 55 y 60',
    between_61_70: 'Entre 61 y 70',
    between_71_80: 'Entre 71 y 80',
    between_81_85: 'Entre 81 y 85',
    cdr_must_be_05: 'CDR tiene que ser 0.5',
    cdr_values: '0 = No demencia / 0.5 = Cuestionable / 1 = Leve / 2 = Moderada / 3 = Grave',
    ci: 'IC',
    client: 'Cliente',
    code: 'Código',
    completed: 'Completado',
    conclusion: 'Conclusión',
    created: 'Creado',
    e4_carrier_non: 'Portador E4 / No portador E4',
    edit_patient: 'Editar paciente',
    edit_user: 'Editar usuario',
    email: 'Email',
    female: 'Mujer',
    first_name: 'Nombre',
    full_name: 'Nombre completo',
    interpretation: 'Interpretación',
    last_name: 'Apellidos',
    male: 'Hombre',
    male_female: 'Hombre / Mujer',
    maintenance: 'MAP-AD Test está en mantenimiento en estos momentos. Por favor, inténtelo de nuevo más tarde.',
    medical_association: 'Asociación médica',
    medical_position: 'Cargo',
    methylcytosines:
      'El ADN del paciente ha sido utilizado en el proceso de laboratorio del MAP-AD Test Oligos, siguiendo su manual de usuario. Las citosinas mitocondriales metiladas han sido medidas usando el MiSeq® System (Illumina) y analizadas por el MAP-AD Test Software para obtener los resultados.',
    methodology: 'Metodología',
    must_be_0_18: 'Tiene que ser un número entre 0 y 18',
    must_be_0_30: 'Tiene que ser un número entre 0 y 30',
    must_be_1_100000: 'Tiene que ser un número entre 1 y 100000',
    must_be_55_85: 'Tiene que ser un número entre 55 y 85',
    na: 'N/D',
    neurological_conclusion: 'Conclusión neurológica',
    neurologist: 'Neurólogo',
    new_patient: 'Nuevo paciente',
    new_user: 'Nuevo usuario',
    negative: 'Negativo',
    not_available: 'No disponible',
    not_found: 'Página no encontrada (error 404)',
    password_change: 'Cambio de contraseña',
    password_required_sign_report: 'Contraseña (necesaria para firmar el informe)',
    patient: 'Paciente',
    patients: 'Pacientes',
    pending: 'Pendiente',
    pet: 'PET Amiloide',
    positive: 'Positivo',
    pos_neg: 'Positivo / Negativo',
    prob_ad: 'Probabilidad de EA',
    prob_ctrl: 'Probabilidad de Control',
    prob_mci: 'Probabilidad de DCL',
    prob_non_progressed: 'Probabilidad de No Progresión',
    prob_progressed: 'Probabilidad de Progresión',
    purchased_requests: 'Solicitudes compradas',
    registration_number: 'Número de colegiado',
    req_email: 'Dirección de correo electrónico válida necesaria',
    req_info: 'Información necesaria',
    req_info_2_15_chars: 'Información necesaria (de 2 a 15 caracteres)',
    req_info_2_25_chars: 'Información necesaria (de 2 a 25 caracteres)',
    req_info_5_25_chars: 'Información necesaria (de 5 a 25 caracteres)',
    req_info_7_chars: 'Información necesaria (exactamente 7 caracteres, formato AB12345)',
    result: 'Resultado',
    role: 'Rol',
    select_conclusion: 'Selecciona una conclusión',
    sex: 'Sexo',
    signature: 'Firma',
    unknown_role: 'Rol desconocido',
    username: 'Usuario',
    username_required_sign_report: 'Usuario (necesario para firmar el informe)',
    users: 'Usuarios',
    waiting_sample: 'Esperando muestra',
  },
  feedback: {
    cdr_must_be_05: 'Para predecir la probabilidad de progresión de un paciente es necesario que su CDR sea 0.5.',
    confirm_action: 'Por favor, confirme esta acción',
    confirm_correct: '¿Confirma que es correcto?',
    delete_patient: 'Borrar paciente {{code}} con las siguientes características: {{info}}',
    delete_user: 'Borrar usuario {{user}}',
    email_sent_user:
      'Se ha enviado un correo electrónico a la dirección de correo del usuario con esta información. No necesita hacer nada más al respecto.',
    enter_conclusion: 'Por favor, introduzca su conclusión neurológica.',
    no_patients_meeting_criteria: 'No hay pacientes que cumplan estos criterios.',
    no_users_meeting_criteria: 'No hay usuarios que cumplan estos criterios.',
    not_logged_in: 'No ha iniciado sesión o su sesión ha caducado. Por favor, inicie sesión para continuar.',
    password_change_required:
      'Necesita cambiar la contraseña temporal que ADmit Therapeutics le ha proporcionado antes de acceder a esta información.',
    password_changed: 'Su contraseña se ha modificado. Por favor, vuelva a conectarse usando la nueva contraseña.',
    password_not_changed:
      'La contraseña no se ha podido modificar. Por favor, verifique que la contraseña es correcta e inténtelo de nuevo. Contacte con ADmit Therapeutics si necesita ayuda.',
    password_not_reset:
      'La contraseña no se ha podido restablecer. Por favor, pruébelo de nuevo más tarde o contacte con ADmit Therapeutics si necesita ayuda.',
    patient_deleted: 'El paciente {{patient}} se ha borrado con éxito.',
    patient_not_deleted:
      'El paciente no se ha podido borrar. Por favor, pruébelo de nuevo más tarde o contacte con ADmit Therapeutics si necesita ayuda.',
    patient_not_saved:
      'El paciente no se ha podido guardar. Por favor, compruebe que todos los campos se encuentran completos e inténtelo de nuevo. Si el problema persiste, contacte con ADmit Therapeutics para recibir ayuda.',
    patient_saved: 'Nuevo paciente creado con el siguiente código y estas características:',
    pdf_report_not_delivered:
      'No se ha podido descargar el informe en formato PDF. Por favor, inténtelo de nuevo más tarde.',
    please_wait_request_processed: 'Por favor, espere mientras su petición se está procesando...',
    purchased_requests_exhausted:
      'No es posible crear este paciente porque no le quedan solicitudes. Por favor, contacte con ADmit Therapeutics.',
    purchased_requests_info:
      '{{purchased}} solicitudes compradas – {{created}} pacientes creados – {{left}} solicitudes restantes',
    remember_code_patient: 'Recuerde que puede consultar el código de cada paciente en su registro.',
    reset_password: 'Restablecer contraseña para el usuario {{user}}',
    select_option: 'Por favor, proceda a seleccionar la opción deseada del menú.',
    session_expired:
      'Su sesión ha caducado. Por favor, vuelva a conectarse o contacte con ADmit Therapeutics si no recuerda su contraseña.',
    sign_report_auth_failed:
      'Autenticación fallida. Por favor, introduzca un usuario y contraseña correctos para firmar este informe.',
    signature_file_required: 'Para el rol de neurólogo escogido se necesita un archivo que contenga la firma.',
    signature_not_uploaded:
      'El archivo que contiene la firma no se ha podido guardar en el servidor. Por favor, inténtelo de nuevo más tarde o contacte con ADmit Therapeutics si necesita ayuda.',
    user_deleted: 'El usuario {{user}} se ha borrado con éxito.',
    user_not_deleted:
      'El usuario no se ha podido borrar. Por favor, pruébelo de nuevo más tarde o contacte con ADmit Therapeutics si necesita ayuda.',
    user_not_saved:
      'El usuario no se ha podido guardar. Por favor, compruebe que todos los campos se encuentran completos e inténtelo de nuevo. Si el problema persiste, contacte con ADmit Therapeutics para recibir ayuda.',
    user_not_saved_duplicated:
      'El usuario no se ha podido guardar. Por favor, compruebe que no existe otro usuario con el mismo nombre de usuario o dirección de correo electrónico.',
    user_saved: 'Usuario <strong>{{user}}</strong> creado/actualizado con una contraseña temporal secreta.',
    welcome: 'Bienvenido a MAP-AD Test.',
    will_get_email_results:
      'Recibirá un correo electrónico cuando el resultado de su paciente se encuentre disponible.',
  },
  legal: {
    title: 'Información legal',
    udi: '08437062887070',
    company: 'ADmit Therapeutics',
    focused:
      'Enfocado en el desarrollo y comercialización de una tecnología novel para la detección temprana de la Enfermedad de Alzheimer',
    description:
      'Este software es un dispositivo médico de diagnóstico in vitro (IVD) para el pronóstico de la Demencia por Enfermedad de Alzheimer (DEA) en pacientes con deterioro cognitivo leve (DCL) a través del análisis de ADN extraído de sangre total y datos clínicos. Solo para uso profesional.',
    version: 'Versión del software',
    serial: 'Número de serie',
    cookies:
      'Este sitio web utiliza sus propias cookies y cookies de terceros para recolectar información con el objetivo de mejorar nuestro servicio. Su uso continuado constituye la aceptación de la instalación de estas cookies. El usuario tiene la opción de configurar el navegador para prevenir la instalación de dichas cookies, aunque esto puede ocasionar dificultades en el uso de la página web.',
    warnings: 'Advertencias',
    warnings_text_1: 'El MAP-AD Test Software es para uso de diagnóstico in vitro.',
    warnings_text_2: 'Este software debe ser utilizado únicamente por profesionales de la salud o personal capacitado.',
    precautions: 'Precauciones',
    precautions_text1:
      'No desconecte el ordenador de la toma de corriente o el cable de Internet, ya que no podrá utilizar el software.',
    precautions_text2:
      'Verifique la compatibilidad de su sistema operativo y navegador para asegurarse de que el software pueda funcionar. Nota: JavaScript habilitado es un requisito.',
    precautions_text3:
      'Evite la opción de completar automáticamente el usuario y la contraseña en el navegador, ya que otra persona podría usar su usuario. Nota: En caso de pérdida u olvido del usuario o de la contraseña, póngase en contacto con ADmit Therapeutics.',
    precautions_text4:
      'Evite distracciones cuando introduzca la información en los campos obligatorios, un dato incorrecto potencialmente resultará en un resultado incorrecto para el paciente.',
    precautions_text5:
      'Evite distracciones al registrar el identificador de la muestra o al identificar las muestras físicas, ya que el paciente podría recibir resultados cruzados.',
    precautions_text6:
      'Evite las distracciones al solicitar la creación del usuario, ya que un correo electrónico incorrecto puede provocar que no se reciban actualizaciones del paciente.',
    precautions_text7:
      'Verifique que el IFU se encuentra disponible para su consulta. ADmit Therapeutics le puede proporcionar el IFU bajo petición si es necesario.',
    precautions_text8:
      'Verifique que su usuario y contraseña son correctos. ADmit Therapeutics le puede proporcionar una nueva contraseña bajo petición.',
  },
}
