import { useEffect } from 'react'
import { useLogin } from '../hooks/useLogin'
import { useLogout } from '../hooks/useLogout'
import { useDataContext } from '../contexts/DataContext'
import { reauthenticate } from '../functions/api'
import { getToken, isSessionAlive, token, touchSession } from '../functions/localStorage'

const WindowEvents = () => {
  const login = useLogin()
  const logout = useLogout()
  const { currentUserId } = useDataContext()

  // Touching session timestamp on local storage.
  useEffect(() => {
    const interval = window.setInterval(touchSession, 1000)

    return () => {
      window.clearInterval(interval)
    }
  }, [])

  // Handle login or logout on window/tab loading.
  useEffect(() => {
    const handleLoad = async () => {
      if (isSessionAlive() && getToken()) {
        const loginResponse = await reauthenticate()
        if (loginResponse) login(loginResponse)
      } else {
        logout()
      }
    }

    // Without this check, it works on Chrome and Firefox, but not on Safari and Edge.
    if (document.readyState === 'complete') {
      handleLoad()
    } else {
      window.addEventListener('load', handleLoad)
    }

    return () => {
      window.removeEventListener('load', handleLoad)
    }
  }, [login, logout])

  // Handle login or logout on local storage change.
  useEffect(() => {
    const handleStorage = async (event: StorageEvent) => {
      if (event.key !== token) return

      if (!getToken()) {
        logout()
      } else if (!currentUserId) {
        const loginResponse = await reauthenticate()
        if (loginResponse) login(loginResponse)
      }
    }

    window.addEventListener('storage', handleStorage)

    return () => {
      window.removeEventListener('storage', handleStorage)
    }
  }, [currentUserId, login, logout])

  return <></>
}

export default WindowEvents
