import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLogout } from '../../hooks/useLogout'
import { useModalContext } from '../../contexts/ModalContext'
import '../../modal.css'

const ModalPasswordChanged = () => {
  const { t } = useTranslation()
  const { passwordChanged, setPasswordChanged } = useModalContext()
  const logout = useLogout()
  const navigate = useNavigate()

  const goHome = () => {
    logout()
    setPasswordChanged(false)
    navigate('/')
  }

  if (!passwordChanged) return <></>

  return (
    <div className='modal-background'>
      <div className='modal-content'>
        <p>{t('feedback.password_changed')}</p>
        <br />
        <button type='button' onClick={goHome}>
          {t('login.sign_in')}
        </button>
      </div>
    </div>
  )
}

export default ModalPasswordChanged
