import { useTranslation } from 'react-i18next'
import { useConfirmationContext } from '../../contexts/ConfirmationContext'
import '../../modal.css'

const ModalResetPasswordConfirm = () => {
  const { t } = useTranslation()

  const { resetPasswordConfirmationText, setResetPasswordConfirmationText, setResetPasswordConfirmed } =
    useConfirmationContext()

  const confirm = () => {
    setResetPasswordConfirmed(true)
    setResetPasswordConfirmationText(null)
  }

  const cancel = () => {
    setResetPasswordConfirmationText(null)
  }

  if (!resetPasswordConfirmationText) return <></>

  return (
    <div className='modal-background'>
      <div className='modal-content'>
        <p className='h4'>{t('feedback.confirm_action')}</p>
        <p>{resetPasswordConfirmationText}</p>
        <br />
        <button type='button' className='btn-warning' style={{ float: 'right' }} onClick={confirm}>
          {t('button.yes_reset_password')}
        </button>
        <button type='button' onClick={cancel}>
          {t('button.no_cancel')}
        </button>
      </div>
    </div>
  )
}

export default ModalResetPasswordConfirm
