import { useTranslation } from 'react-i18next'
import ChangePasswordForm from '../components/ChangePasswordForm'

const PageChangePassword = () => {
  const { t } = useTranslation()

  return (
    <>
      <h1>{t('label.password_change')}</h1>
      <ChangePasswordForm />
    </>
  )
}

export default PageChangePassword
