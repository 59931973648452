import Patient from '../models/Patient'
import User from '../models/User'
import i18n from '../i18n'

export const useFormatter = () => {
  const sex = (s: boolean | undefined) => (s ? i18n.t('label.male') : i18n.t('label.female'))

  const naPosNeg = (pn: boolean | undefined | null) =>
    pn === null ? i18n.t('label.not_available') : pn ? i18n.t('label.positive') : i18n.t('label.negative')

  const dateTime = (dt: string) => {
    const date = new Date(Date.parse(dt))
    return `${date.toLocaleDateString('en-GB')} at ${date.toLocaleTimeString('en-GB')}`
  }

  const infoUser = (u: User) => `${u.username.toLowerCase()} – ${u.firstName} ${u.lastName} – ${u.role}`

  const infoPatient = (p: Patient) => {
    const age = `${i18n.t('label.age')}: ${p.age}`
    const mmse = p.mmse !== null ? p.mmse : i18n.t('label.not_available')
    const pet = `${i18n.t('label.pet')}: ${naPosNeg(p.amyloidPet)}`
    return `${sex(p.sex)} – ${age} – CDR: ${p.cdr} – SOB: ${p.sob} – MMSE: ${mmse} – ApoE: ${p.apoe} – ${pet}`
  }

  const infoRole = (role: string) => {
    if (role === 'client') return i18n.t('label.client')
    if (role === 'neuro') return i18n.t('label.neurologist')
    if (role === 'admin') return i18n.t('label.administrator')
    return i18n.t('label.unknown_role')
  }

  return { sex, naPosNeg, dateTime, infoUser, infoPatient, infoRole }
}
