import { useDataContext } from '../contexts/DataContext'
import { deleteToken } from '../functions/localStorage'

export const useLogout = () => {
  const {
    setCurrentUserId,
    setCurrentUserRole,
    setCurrentUserFullName,
    setCurrentUserCode,
    setCurrentUserTemporaryPassword,
    setCurrentUserPurchasedRequests,
  } = useDataContext()

  const logout = () => {
    deleteToken()
    setCurrentUserId(null)
    setCurrentUserRole(null)
    setCurrentUserFullName(null)
    setCurrentUserCode(null)
    setCurrentUserTemporaryPassword(false)
    setCurrentUserPurchasedRequests(null)
  }

  return logout
}
