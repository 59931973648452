import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormatter } from '../../hooks/useFormatter'
import { useConfirmationContext } from '../../contexts/ConfirmationContext'
import { useDataContext } from '../../contexts/DataContext'
import { useModalContext } from '../../contexts/ModalContext'
import { addOne, deleteOne, getOne, updateOne } from '../../functions/api'
import Patient from '../../models/Patient'
import PatientConfirmation from './Confirmation'
import PatientForm from './Form'

type Props = {
  id: string | undefined
}

const emptyPatient: Patient = {
  id: null,
  userId: null,
  code: '',
  cdr: -1,
  sob: 0,
  mmse: null,
  age: 55,
  amyloidPet: undefined,
  apoe: undefined,
  sex: undefined,
  probCtrl: null,
  probMci: null,
  probAd: null,
  neuroId: null,
  neuroEval: null,
  neuroDate: null,
}

/**
 * Component to manage the workflow for creating a patient. It contains two main subcomponents:
 * PatientForm and PatientConfirmation. PatientForm is displayed first and is used to enter patient data.
 * PatientConfirmation is displayed next and is used to ask for confirmation of previously entered data.
 */
const PatientMaster = ({ id }: Props) => {
  const { t } = useTranslation()
  const { infoPatient } = useFormatter()
  const navigate = useNavigate()

  const { setExpired, setCreatedPatient, setCreatedCode, setModalMessage, setSpinner } = useModalContext()
  const { deleteConfirmed, setDeleteConfirmed, setDeleteConfirmationText } = useConfirmationContext()
  const { currentUserId, currentUserRole } = useDataContext()

  const [patient, setPatient] = useState<Patient>(emptyPatient)
  const [completed, setCompleted] = useState(false)

  // Set patient to previously existing patient if an id has been received.
  useEffect(() => {
    const fetchPatient = async () => {
      if (id) {
        const response = await getOne<Patient>('patients/', id)
        if (response.status === 401) setExpired(true)
        if (response.status === 404) navigate('/error404')
        if (response.status === 200 && response.data) {
          const patient = response.data
          if (patient.neuroId) navigate('/error404')
          setPatient(patient)
        }
      } else {
        setPatient(emptyPatient)
      }
    }

    fetchPatient()
  }, [id, navigate, setExpired])

  // Send DELETE request to API if user has confirmed deletion.
  useEffect(() => {
    const deletePatient = async () => {
      setDeleteConfirmed(false)

      const response = await deleteOne('patients/', patient.id ?? '')

      if (response.success) {
        setModalMessage(t('feedback.patient_deleted', { patient: patient.code }))
        navigate('/patient/list')
      } else {
        const error = response.error ? ` (Error ${response.error})` : ''
        setModalMessage(t('feedback.patient_not_deleted') + error)
      }
    }

    if (deleteConfirmed) deletePatient()
  }, [t, deleteConfirmed, setDeleteConfirmed, navigate, setModalMessage, patient])

  // Set patient as not completed in order to be able to keep editing its fields.
  const keepEditing = () => {
    setCompleted(false)
  }

  // Send POST or PUT request to API in order to create or update a patient.
  const upsert = async () => {
    setSpinner(true)

    const response = patient.id ? await updateOne('patients/', patient) : await addOne('patients/', patient)

    if (response.error === '400/626') {
      setSpinner(false)
      const error = response.error ? ` (Error ${response.error})` : ''
      setModalMessage(t('feedback.purchased_requests_exhausted') + error)
      return
    }

    if (response.status !== 201 && response.status !== 204) {
      setSpinner(false)
      const error = response.error ? ` (Error ${response.error})` : ''
      setModalMessage(t('feedback.patient_not_saved') + error)
      return
    }

    if (!patient.id) {
      setCreatedCode(response.data!.code)
      setCreatedPatient(infoPatient(patient))
    }

    setSpinner(false)
    navigate('/patient/list')
  }

  // Send DELETE request to API.
  const onDelete = async () => {
    setDeleteConfirmationText(t('feedback.delete_patient', { code: patient.code, info: infoPatient(patient) }))
  }

  const showDeleteButton = patient.id !== null && (currentUserRole === 'client' || currentUserRole === 'admin')

  if (!id && currentUserRole !== 'client') return <></>
  if (patient.userId !== null && patient.userId !== currentUserId && currentUserRole !== 'neuro') return <></>

  return (
    <>
      {completed && <PatientConfirmation patient={patient} onConfirm={upsert} onCancel={keepEditing} />}

      {!completed && <PatientForm patient={patient} setPatient={setPatient} setCompleted={setCompleted} />}

      {showDeleteButton && (
        <button type='button' className='btn-danger' style={{ float: 'right', marginTop: '0.5em' }} onClick={onDelete}>
          {t('button.delete_patient')}
        </button>
      )}
    </>
  )
}

export default PatientMaster
