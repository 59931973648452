import { useTranslation } from 'react-i18next'
import { useDataContext } from '../contexts/DataContext'
import PatientDirectory from '../components/Patient/Directory'
import Restricted from '../components/Restricted'
import TempPassword from '../components/TempPassword'
import DnaIcon from '../images/dna.svg'

const PagePatientList = () => {
  const { t } = useTranslation()

  const { currentUserId, currentUserTemporaryPassword } = useDataContext()

  if (currentUserTemporaryPassword) return <TempPassword />

  return (
    <>
      <h1>
        {t('label.patients')}
        <img src={DnaIcon} alt='DNA icon' />
      </h1>
      {currentUserId ? <PatientDirectory /> : <Restricted />}
    </>
  )
}

export default PagePatientList
