import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import ConfirmationProvider from './contexts/ConfirmationContext'
import DataProvider from './contexts/DataContext'
import FiltersProvider from './contexts/FiltersContext'
import ModalProvider from './contexts/ModalContext'
import TranslationsProvider from './contexts/TranslationsContext'

import { getLanguage } from './functions/localStorage'

import MaintenanceData from './models/MaintenanceData'

import ModalDeleteConfirm from './components/Modal/DeleteConfirm'
import ModalExpired from './components/Modal/Expired'
import ModalMessage from './components/Modal/Message'
import ModalPasswordChanged from './components/Modal/PasswordChanged'
import ModalPatientCreated from './components/Modal/PatientCreated'
import ModalResetPasswordConfirm from './components/Modal/ResetPasswordConfirm'
import ModalSpinner from './components/Modal/Spinner'
import ModalUserCreated from './components/Modal/UserCreated'

import Footer from './components/Footer'
import Header from './components/Header'
import ReportFooter from './report/Footer'
import ReportHeader from './report/Header'

import PageChangePassword from './pages/ChangePassword'
import PageError404 from './pages/Error404'
import PageHome from './pages/Home'
import PageLegal from './pages/Legal'
import PageMaintenance from './pages/Maintenance'
import PagePatientForm from './pages/PatientForm'
import PagePatientList from './pages/PatientList'
import PagePatientReport from './pages/PatientReport'
import PageUserForm from './pages/UserForm'
import PageUserList from './pages/UserList'

import MaintenanceBanner from './components/MaintenanceBanner'
import WindowEvents from './components/WindowEvents'

import i18n from './i18n'

const App = () => {
  const [report, setReport] = useState(false)

  const [maintenance, setMaintenance] = useState<MaintenanceData>({
    isActive: false,
    isScheduled: false,
    messageCatalan: '',
    messageSpanish: '',
    messageEnglish: '',
  })

  // Check if we need to show maintenance banner or mode every 15 minutes.
  useEffect(() => {
    const checkMaintenance = async () => {
      const response = await fetch('/maintenance.json')
      if (response.ok) {
        const data = await response.json()
        setMaintenance(data)
      }
    }

    checkMaintenance()

    const fifteenMinutes = 15 * 60 * 1000
    const interval = setInterval(() => checkMaintenance(), fifteenMinutes)

    return () => clearInterval(interval)
  }, [])

  // Change language if the user has changed it before.
  useEffect(() => {
    const savedLanguage = getLanguage()
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage)
    }
  }, [])

  // Main content (not maintenance).
  const mainContent = (
    <>
      {report ? <ReportHeader /> : <Header />}
      <div className='container'>
        <Routes>
          <Route path='/' element={<PageHome />} />
          <Route path='/changepassword' element={<PageChangePassword />} />
          <Route path='/patient/report/:id' element={<PagePatientReport setReport={setReport} />} />
          <Route path='/patient/list' element={<PagePatientList />} />
          <Route path='/patient/add' element={<PagePatientForm />} />
          <Route path='/patient/:id' element={<PagePatientForm />} />
          <Route path='/user/list' element={<PageUserList />} />
          <Route path='/user/add' element={<PageUserForm />} />
          <Route path='/user/:id' element={<PageUserForm />} />
          <Route path='/legal' element={<PageLegal />} />
          <Route path='*' element={<PageError404 />} />
        </Routes>
      </div>
      {report ? <ReportFooter /> : <Footer />}
    </>
  )

  // Maintenance content.
  const maintenanceContent = (
    <>
      <Header />
      <div className='container-maintenance'>
        <PageMaintenance />
      </div>
      <Footer />
    </>
  )

  return (
    <DataProvider>
      <TranslationsProvider>
        <FiltersProvider>
          <ModalProvider>
            <ConfirmationProvider>
              <Router>
                <ModalSpinner />
                <ModalExpired />
                <ModalPasswordChanged />
                <ModalUserCreated />
                <ModalPatientCreated />
                <ModalDeleteConfirm />
                <ModalResetPasswordConfirm />
                <ModalMessage />
                <MaintenanceBanner data={maintenance} />
                {maintenance.isActive ? maintenanceContent : mainContent}
                <WindowEvents />
              </Router>
            </ConfirmationProvider>
          </ModalProvider>
        </FiltersProvider>
      </TranslationsProvider>
    </DataProvider>
  )
}

export default App

// Note about maintenance:
//
// When we want to put the app in maintenance mode, we need to edit the maintenance.json file in two ways:
// 1. Some days before the maintenance:
//    1.1 Set isScheduled to true.
//    1.2 Set the three messages in the three languages to the message we want to show to the users.
// 2. When the maintenance starts:
//    2.1 Set isActive to true.
// 3. When the maintenance ends:
//    3.1 Set isActive to false.
//    3.2 Set isScheduled to false.
//
// nginx:
//
// location /maintenance.json {
//     add_header Cache-Control "no-cache, no-store, must-revalidate";
//     add_header Pragma no-cache;
//     add_header Expires 0;
// }
