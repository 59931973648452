type Props = {
  title: string
  highlight: boolean
}

const SectionHeader = ({ title, highlight }: Props) => {
  return <div className={`report-section-header ${highlight ? 'report-section-header-highlight' : ''}`}>{title}</div>
}

export default SectionHeader
