import { useTranslation } from 'react-i18next'
import SectionHeader from './SectionHeader'

type Props = {
  pNo: number
  pYes: number
}

const Result = ({ pNo, pYes }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <SectionHeader title={t('label.result')} highlight={false} />
      <table className='report-result'>
        <tbody>
          <tr>
            <td className='report-prob-text'>{t('label.prob_non_progressed')}:</td>
            <td className='report-prob-value'>{pNo.toFixed(1)} %</td>
            <td className='report-prob-chart'>
              <div style={{ backgroundColor: '#3357', width: pNo + '%' }}></div>
            </td>
          </tr>
          <tr>
            <td className='report-prob-text'>{t('label.prob_progressed')}:</td>
            <td className='report-prob-value'>{pYes.toFixed(1)} %</td>
            <td className='report-prob-chart'>
              <div style={{ backgroundColor: '#944', width: pYes + '%' }}></div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td className='report-accuracy'>
              {t('label.accuracy')}: 0.8319 &ndash; 95% {t('label.ci')} (0.7524, 0.8942)
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default Result
