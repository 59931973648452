import { useTranslation } from 'react-i18next'
import { useFormatter } from '../../hooks/useFormatter'
import { host } from '../../functions/api'
import User from '../../models/User'

type Props = {
  user: User
  onConfirm: () => Promise<void>
  onCancel: () => void
}

/**
 * Subcomponent of UserMaster used to ask for confirmation of previously entered data.
 */
const UserConfirmation = ({ user, onConfirm, onCancel }: Props) => {
  const { t } = useTranslation()
  const { infoRole } = useFormatter()

  return (
    <>
      <p className='h4'>{t('feedback.confirm_correct')}</p>

      <table className='table-cell table-conf'>
        <tbody>
          <tr>
            <td className='td-cell-label'>{t('label.username')}:</td>
            <td className='td-cell-value'>{user.username.toLowerCase()}</td>
          </tr>
          <tr>
            <td className='td-cell-label'>{t('label.first_name')}:</td>
            <td className='td-cell-value'>{user.firstName}</td>
          </tr>
          <tr>
            <td className='td-cell-label'>{t('label.last_name')}:</td>
            <td className='td-cell-value'>{user.lastName}</td>
          </tr>
          <tr>
            <td className='td-cell-label'>{t('label.email')}:</td>
            <td className='td-cell-value'>{user.email}</td>
          </tr>
          <tr>
            <td className='td-cell-label'>{t('label.role')}:</td>
            <td className='td-cell-value'>{infoRole(user.role)}</td>
          </tr>
          {user.role === 'neuro' && (
            <>
              <tr>
                <td className='td-cell-label'>{t('label.medical_position')}:</td>
                <td className='td-cell-value'>{user.medicalPosition}</td>
              </tr>
              <tr>
                <td className='td-cell-label'>{t('label.registration_number')}:</td>
                <td className='td-cell-value'>{user.registrationNumber}</td>
              </tr>
              <tr>
                <td className='td-cell-label'>{t('label.medical_association')}:</td>
                <td className='td-cell-value'>{user.medicalAssociation}</td>
              </tr>
              <tr>
                <td className='td-cell-label'>{t('label.signature')}:</td>
                <td className='td-cell-value'>
                  <img
                    src={`${host}d0138a9f-2962-4cef-93fc-733911b5d75e/${user.signatureFile}`}
                    alt={t('label.signature') ?? ''}
                    style={{ width: '10em' }}
                  />
                </td>
              </tr>
            </>
          )}
          {user.role === 'client' && (
            <>
              <tr>
                <td className='td-cell-label'>{t('label.code')}:</td>
                <td className='td-cell-value'>{user.code}</td>
              </tr>
              <tr>
                <td className='td-cell-label'>{t('label.purchased_requests')}:</td>
                <td className='td-cell-value'>{user.purchasedRequests}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>

      <button type='button' onClick={onConfirm}>
        {t('button.yes_save_changes')}
      </button>
      <button type='button' onClick={onCancel}>
        {t('button.no_take_me_back')}
      </button>
    </>
  )
}

export default UserConfirmation
