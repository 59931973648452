import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getPdfReport } from '../functions/api'

type Props = {
  patientCode: string
  patientId: string
}

const PdfReportLink = ({ patientCode, patientId }: Props) => {
  const [url, setUrl] = useState<string | null>(null)
  const { t } = useTranslation()

  useEffect(() => {
    const fetchPdfReport = async () => {
      const response = await getPdfReport(patientId)

      if (response.status === 200 && response.data) {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        setUrl(URL.createObjectURL(blob))
      }
    }

    fetchPdfReport()

    return () => {
      if (url) URL.revokeObjectURL(url)
    }
  }, [patientId, url])

  const downloadFile = () => {
    if (url) {
      const a = document.createElement('a')
      a.href = url
      a.download = `map-adtest-${patientCode}.pdf`
      a.click()
    }
  }

  if (!url) return <></>

  return <button onClick={downloadFile}>{t('button.donwload_report')}</button>
}

export default PdfReportLink
