import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { softwareVersion } from '../functions/api'
import ManufacturerIcon from '../images/manufacturer.svg'
import UdiIcon from '../images/udi.svg'

const SERIAL_NUMBER = 'AD002YYMMDD'

const Footer = () => {
  const { t } = useTranslation()
  const [version, setVersion] = useState('Unknown')

  useEffect(() => {
    const fetchVersion = async () => {
      const response = await softwareVersion()
      setVersion(response)
    }

    fetchVersion()
  }, [])

  return (
    <footer>
      <p className='footer-title'>{t('legal.company')}</p>
      <p>
        <strong>{t('legal.focused')}</strong>
      </p>
      <div className='footer-info'>
        <div className='footer-info-left'>
          <p>
            <strong>{t('legal.description')}</strong>
          </p>
          <p>
            <strong>
              {t('legal.version')} – {version}
            </strong>
            <br />
            <strong>
              {t('legal.serial')} – {SERIAL_NUMBER}
            </strong>
          </p>
          <p className='udi'>
            <img src={UdiIcon} alt='Icon' className='icon icon-udi' />
            <strong>
              (01) {t('legal.udi')} (21) {SERIAL_NUMBER} (8012) {version}
            </strong>
          </p>
          <p>{t('legal.cookies')}</p>
        </div>
        <div className='footer-info-right'>
          <img src={ManufacturerIcon} alt='Icon' className='icon icon-manufacturer' />
          <p className='address'>
            ADmit Therapeutics, S.L.
            <br />
            Carrer Joan XXIII, 10
            <br />
            08950 Esplugues de Llobregat
            <br />
            Barcelona &ndash; Spain
            <br />
            +34 938 015 348
            <br />
            mapad@admit-therapeutics.com
          </p>
        </div>
      </div>
      <p className='none'>{process.env.REACT_APP_BUILD_TIMESTAMP}</p>
    </footer>
  )
}

export default Footer
