import { useTranslation } from 'react-i18next'
import SectionHeader from './SectionHeader'

type Props = {
  code: string
  sex: string
  age: number
  cdr: number
  sob: number
  mmse: number | null
  apoe4: string
  pet: string
}

const PatientInfo = ({ code, sex, age, cdr, sob, mmse, apoe4, pet }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <SectionHeader title={t('label.patient')} highlight={false} />
      <table>
        <tbody>
          <tr>
            <td className='report-info-text'>{t('label.code')}:</td>
            <td className='report-patient-value'>{code}</td>
            <td className='report-patient-legend'></td>
          </tr>
          <tr>
            <td className='report-info-text'>{t('label.sex')}:</td>
            <td className='report-patient-value'>{sex}</td>
            <td className='report-patient-legend'></td>
          </tr>
          <tr>
            <td className='report-info-text'>{t('label.age')}:</td>
            <td className='report-patient-value'>{age}</td>
            <td className='report-patient-legend'></td>
          </tr>
          <tr>
            <td className='report-info-text'>CDR:</td>
            <td className='report-patient-value'>{cdr}</td>
            <td className='report-patient-legend'>{t('label.cdr_values')}</td>
          </tr>
          <tr>
            <td className='report-info-text'>CDR-SOB:</td>
            <td className='report-patient-value'>{sob}</td>
            <td className='report-patient-legend'>[ 0 - 18 ]</td>
          </tr>
          <tr>
            <td className='report-info-text'>MMSE:</td>
            <td className='report-patient-value'>{mmse !== null ? mmse : t('label.not_available')}</td>
            <td className='report-patient-legend'>[ 0 - 30 ]</td>
          </tr>
          <tr>
            <td className='report-info-text'>{t('label.apoe_genotype')}:</td>
            <td className='report-patient-value'>{apoe4}</td>
            <td className='report-patient-legend'>{t('label.e4_carrier_non')}</td>
          </tr>
          <tr>
            <td className='report-info-text'>A&beta; PET Scan:</td>
            <td className='report-patient-value'>{pet}</td>
            <td className='report-patient-legend'>{t('label.pos_neg')}</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default PatientInfo
