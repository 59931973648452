import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLogin } from '../hooks/useLogin'
import { authenticate } from '../functions/api'
import LoginRequest from '../models/LoginRequest'

const emptyLoginRequest: LoginRequest = {
  username: '',
  password: '',
}

const Login = () => {
  const [loginRequest, setLoginRequest] = useState<LoginRequest>(emptyLoginRequest)
  const [wrongData, setWrongData] = useState(false)
  const { t } = useTranslation()
  const login = useLogin()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target
    setLoginRequest({ ...loginRequest, [id]: value })
  }

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    // Client validation.
    if (loginRequest.username === '' || loginRequest.password === '') {
      setWrongData(true)
      return
    }

    // Server authentication.
    const loginResponse = await authenticate(loginRequest)

    if (loginResponse) {
      login(loginResponse)
    } else {
      setWrongData(true)
    }
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className='form-box'>
          <label htmlFor='username'>{t('login.username')}</label>
          <input
            type='text'
            id='username'
            name='username'
            autoComplete='false'
            value={loginRequest.username}
            onChange={handleChange}
          />

          <label htmlFor='password'>{t('login.password')}</label>
          <input type='password' id='password' name='password' value={loginRequest.password} onChange={handleChange} />
        </div>

        <button type='submit'>{t('login.sign_in')}</button>
      </form>

      {wrongData && (
        <div className='wrong-login'>
          <p>{t('login.incorrect')}</p>
        </div>
      )}
    </>
  )
}

export default Login
