import { useTranslation } from 'react-i18next'
import Patient from '../../models/Patient'
import PatientTableRow from './TableRow'

type Props = {
  patients: Patient[]
}

const PatientTable = ({ patients }: Props) => {
  const { t } = useTranslation()

  return (
    <table className='entity'>
      <thead>
        <tr>
          <th className='th-12pc'>{t('label.code')}</th>
          <th className='th-8pc'>{t('label.sex')}</th>
          <th className='th-5pc'>{t('label.age')}</th>
          <th className='th-5pc'>CDR</th>
          <th className='th-5pc'>SOB</th>
          <th className='th-5pc'>MMSE</th>
          <th className='th-6pc'>ApoE</th>
          <th className='th-10pc'>A&beta; PET</th>
          <th className='th-6pc'>Ctrl %</th>
          <th className='th-6pc'>MCI %</th>
          <th className='th-6pc'>AD %</th>
          <th>{t('label.conclusion')}</th>
        </tr>
      </thead>
      <tbody>
        {patients.map((patient) => (
          <PatientTableRow key={patient.id} patient={patient} />
        ))}
      </tbody>
    </table>
  )
}

export default PatientTable
