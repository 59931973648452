import { useTranslation } from 'react-i18next'
import { useDataContext } from '../contexts/DataContext'
import Login from '../components/Login'
import TempPassword from '../components/TempPassword'

const PageHome = () => {
  const { currentUserId, currentUserTemporaryPassword } = useDataContext()
  const { t } = useTranslation()

  if (currentUserTemporaryPassword) return <TempPassword />

  if (!currentUserId) return <Login />

  return (
    <>
      <h1>{t('nav.home')}</h1>
      <h2>{t('feedback.welcome')}</h2>
      <p>{t('feedback.select_option')}</p>
    </>
  )
}

export default PageHome
