import { useTranslation } from 'react-i18next'
import User from '../../models/User'
import UserTableRow from './TableRow'

type Props = {
  users: User[]
}

const UserTable = ({ users }: Props) => {
  const { t } = useTranslation()

  return (
    <table className='entity'>
      <thead>
        <tr>
          <th className='th-12pc'>{t('label.username')}</th>
          <th className='th-12pc'>{t('label.first_name')}</th>
          <th className='th-12pc'>{t('label.last_name')}</th>
          <th>{t('label.email')}</th>
          <th className='th-10pc'>{t('label.role')}</th>
          <th>Created</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => (
          <UserTableRow key={user.id} user={user} />
        ))}
      </tbody>
    </table>
  )
}

export default UserTable
