import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormatter } from '../hooks/useFormatter'
import { useNeuroTranslation } from '../hooks/useNeuroTranslation'
import { useModalContext } from '../contexts/ModalContext'
import { getOne } from '../functions/api'
import Patient from '../models/Patient'
import Interpretation from '../report/Interpretation'
import Methodology from '../report/Methodology'
import PatientInfo from '../report/PatientInfo'
import Result from '../report/Result'

type Props = {
  setReport: Dispatch<SetStateAction<boolean>>
}

const PagePatientReport = ({ setReport }: Props) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { naPosNeg, sex } = useFormatter()
  const { setExpired } = useModalContext()
  const [patient, setPatient] = useState<Patient | null>(null)
  const { translate } = useNeuroTranslation()

  useEffect(() => {
    setReport(true)

    const fetchPatient = async () => {
      if (id) {
        const responsePatient = await getOne<Patient>('patients/', id)
        if (responsePatient.status === 401) setExpired(true)
        if (responsePatient.status === 200 && responsePatient.data) {
          setPatient(responsePatient.data)
        }
      }
    }

    fetchPatient()
  }, [id, setExpired, setReport])

  const neuroEvalText = patient?.neuroEval ? translate(patient.neuroEval) : t('label.pending')

  return (
    patient && (
      <>
        <PatientInfo
          code={patient.code}
          sex={sex(patient.sex)}
          age={patient.age}
          cdr={patient.cdr}
          sob={patient.sob}
          mmse={patient.mmse}
          apoe4={patient.apoe ?? t('label.not_available')}
          pet={naPosNeg(patient.amyloidPet)}
        />
        <Methodology text={t('label.methylcytosines')} />
        <Result pNo={((patient.probCtrl ?? 0) + (patient.probMci ?? 0)) * 100} pYes={(patient.probAd ?? 0) * 100} />
        <Interpretation text={neuroEvalText} patientCode={patient.code ?? ''} patientId={patient.id ?? ''} />
      </>
    )
  )
}

export default PagePatientReport
