import Patient from '../../models/Patient'
import PatientListCell from './ListCell'

type Props = {
  patients: Patient[]
}

const PatientList = ({ patients }: Props) => {
  return (
    <>
      {patients.map((patient) => (
        <PatientListCell key={patient.id} patient={patient} />
      ))}
    </>
  )
}

export default PatientList
