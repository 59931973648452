import { useTranslation } from 'react-i18next'
import { useModalContext } from '../../contexts/ModalContext'
import '../../modal.css'

const ModalPatientCreated = () => {
  const { t } = useTranslation()

  const { createdPatient, createdCode, setCreatedPatient } = useModalContext()

  const close = () => {
    setCreatedPatient(null)
  }

  if (!createdPatient) return <></>

  return (
    <div className='modal-background'>
      <div className='modal-content'>
        <p>{t('feedback.patient_saved')}</p>
        <br />
        <p>
          <strong>{createdCode}</strong>
          <br />
          <br />
          <strong>{createdPatient}</strong>
        </p>
        <br />
        <p>{t('feedback.remember_code_patient')}</p>
        <br />
        <p>{t('feedback.will_get_email_results')}</p>
        <br />
        <button type='button' onClick={close}>
          {t('button.close')}
        </button>
      </div>
    </div>
  )
}

export default ModalPatientCreated
