import { useEffect, useState } from 'react'
import { getAll } from '../functions/api'
import User from '../models/User'

export class UserFilter {
  roleClient: boolean = true
  roleNeuro: boolean = true
  roleAdmin: boolean = true
}

export const useUserFilter = () => {
  const [users, setUsers] = useState<User[]>([])
  const [apiError, setApiError] = useState(0)
  const [filters, setFilters] = useState(new UserFilter())

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await getAll<User>('users/')
      if (response.status === 401) setApiError(401)
      if (response.status === 200) setUsers(response.data.filter((u) => u.role !== 'predict'))
    }

    fetchUsers()
  }, [])

  const toggle = (filter: string, checked: boolean) => {
    // Only allow the toggle if at least one filter is checked after the toggle.
    if (!checked) {
      const group = filter.split(/(?=[A-Z])/)[0]
      const totalChecked = Object.entries(filters).filter(([key, value]) => key.startsWith(group) && value).length
      if (totalChecked <= 1) return
    }

    setFilters({ ...filters, [filter]: checked })
  }

  const reset = () => {
    setFilters(new UserFilter())
  }

  const filteredUsers = () => {
    return users.filter((user) => {
      if (!filters.roleClient && user.role === 'client') return false
      if (!filters.roleNeuro && user.role === 'neuro') return false
      if (!filters.roleAdmin && user.role === 'admin') return false
      return true
    })
  }

  return { filters, apiError, toggle, reset, filteredUsers }
}
