import User from '../../models/User'
import UserListCell from './ListCell'

type Props = {
  users: User[]
}

const UserList = ({ users }: Props) => {
  return (
    <>
      {users.map((user) => (
        <UserListCell key={user.id} user={user} />
      ))}
    </>
  )
}

export default UserList
