import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormatter } from '../../hooks/useFormatter'
import { useConfirmationContext } from '../../contexts/ConfirmationContext'
import { useDataContext } from '../../contexts/DataContext'
import { useModalContext } from '../../contexts/ModalContext'
import { addOne, deleteOne, getOne, updateOne } from '../../functions/api'
import User from '../../models/User'
import UserConfirmation from './Confirmation'
import UserForm from './Form'

type Props = {
  id: string | undefined
}

const emptyUser: User = {
  id: null,
  username: '',
  password: '',
  temporaryPassword: false,
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  code: null,
  purchasedRequests: null,
  medicalPosition: null,
  registrationNumber: null,
  medicalAssociation: null,
  signatureFile: null,
  created: null,
}

/**
 * Component to manage the workflow for creating a user. It contains two main subcomponents:
 * UserForm and UserConfirmation. UserForm is displayed first and is used to enter user data.
 * UserConfirmation is displayed next and is used to ask for confirmation of previously entered data.
 */
const UserMaster = ({ id }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { infoUser } = useFormatter()

  const { currentUserId, currentUserRole } = useDataContext()
  const { setExpired, setCreatedUser, setModalMessage } = useModalContext()
  const {
    deleteConfirmed,
    setDeleteConfirmed,
    setDeleteConfirmationText,
    resetPasswordConfirmed,
    setResetPasswordConfirmed,
    setResetPasswordConfirmationText,
  } = useConfirmationContext()

  const [user, setUser] = useState<User>(emptyUser)
  const [completed, setCompleted] = useState(false)

  // Set user to previously existing user if an id has been received.
  useEffect(() => {
    const fetchUser = async () => {
      if (id) {
        const response = await getOne<User>('users/', id)
        if (response.status === 401) setExpired(true)
        if (response.status === 404) navigate('/error404')
        if (response.status === 200 && response.data) setUser(response.data)
      } else {
        setUser(emptyUser)
      }
    }

    fetchUser()
  }, [id, navigate, setExpired])

  // Send DELETE request to API if user has confirmed deletion.
  useEffect(() => {
    const deleteUser = async () => {
      setDeleteConfirmed(false)

      const response = await deleteOne('users/', user.id ?? '')

      if (response.success) {
        setModalMessage(t('feedback.user_deleted', { user: infoUser(user) }))
        navigate('/user/list')
      } else {
        const error = response.error ? ` (Error ${response.error})` : ''
        setModalMessage(t('feedback.user_not_deleted') + error)
      }
    }

    if (deleteConfirmed) deleteUser()
  }, [t, deleteConfirmed, setDeleteConfirmed, setModalMessage, user, navigate, infoUser])

  // Send PUT request to API to reset password if user has confirmed resetting password.
  useEffect(() => {
    const resetPassword = async () => {
      setResetPasswordConfirmed(false)

      user.password = ''
      const response = await updateOne('users/', user)

      if (response.status !== 204) {
        const error = response.error ? ` (Error ${response.error})` : ''
        setModalMessage(t('feedback.password_not_reset') + error)
        return
      }

      setCreatedUser(infoUser(user))
      navigate('/user/list')
    }

    if (resetPasswordConfirmed) resetPassword()
  }, [t, resetPasswordConfirmed, setResetPasswordConfirmed, setCreatedUser, setModalMessage, user, navigate, infoUser])

  // Set user as not completed in order to be able to keep editing its fields.
  const keepEditing = () => {
    setCompleted(false)
  }

  // Send POST or PUT request to API in order to create or update a user.
  const upsert = async () => {
    const response = user.id ? await updateOne('users/', user) : await addOne('users/', user)

    if (response.status === 400) {
      const error = response.error
      const info = error ? ` (Error ${error})` : ''

      if (error === '400/614') {
        setModalMessage(t('feedback.user_not_saved_duplicated') + info)
      } else {
        setModalMessage(t('feedback.user_not_saved') + info)
      }

      return
    }

    if (response.status !== 201 && response.status !== 204) {
      setModalMessage(t('feedback.user_not_saved'))
      return
    }

    if (!user.id) setCreatedUser(infoUser(user))
    navigate('/user/list')
  }

  const onDelete = async () => {
    setDeleteConfirmationText(t('feedback.delete_user', { user: infoUser(user) }))
  }

  const onResetPassword = async () => {
    setResetPasswordConfirmationText(t('feedback.reset_password', { user: infoUser(user) }))
  }

  const showDeleteButton = user.id !== null && currentUserId !== null && user.id !== currentUserId

  if (currentUserRole !== 'admin') return <></>
  if (id === currentUserId) return <></>

  return (
    <>
      {completed && <UserConfirmation user={user} onConfirm={upsert} onCancel={keepEditing} />}

      {!completed && <UserForm user={user} setUser={setUser} setCompleted={setCompleted} />}

      {showDeleteButton && (
        <>
          <button type='button' className='btn-danger' style={{ float: 'right' }} onClick={onDelete}>
            {t('button.delete_user')}
          </button>
          <button
            type='button'
            className='btn-warning'
            style={{ float: 'right', marginRight: '0.5em' }}
            onClick={onResetPassword}
          >
            {t('button.reset_password')}
          </button>
        </>
      )}
    </>
  )
}

export default UserMaster
