import { useTranslation } from 'react-i18next'
import PdfReportLink from './PdfReportLink'
import SectionHeader from './SectionHeader'

type Props = {
  text: string
  patientCode: string
  patientId: string
}

const Interpretation = ({ text, patientCode, patientId }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <SectionHeader title={t('label.interpretation')} highlight={true} />
      <p>{text}</p>
      <div className='report-download'>
        <PdfReportLink patientCode={patientCode} patientId={patientId} />
      </div>
    </>
  )
}

export default Interpretation
