import { useTranslation } from 'react-i18next'
import { useFormatter } from '../../hooks/useFormatter'
import { useNeuroTranslation } from '../../hooks/useNeuroTranslation'
import Patient from '../../models/Patient'

type Props = {
  patient: Patient
  onConfirm: () => Promise<void>
  onCancel: () => void
}

/**
 * Subcomponent of PatientMaster used to ask for confirmation of previously entered data.
 */
const PatientConfirmation = ({ patient, onConfirm, onCancel }: Props) => {
  const { t } = useTranslation()
  const { naPosNeg, sex } = useFormatter()
  const { translate } = useNeuroTranslation()

  return (
    <>
      <p className='h4'>{t('feedback.confirm_correct')}</p>

      <table className='table-cell table-conf'>
        <tbody>
          {patient.code && (
            <tr>
              <td className='td-cell-label'>{t('label.code')}:</td>
              <td className='td-cell-value'>{patient.code}</td>
            </tr>
          )}
          <tr>
            <td className='td-cell-label'>{t('label.sex')}:</td>
            <td className='td-cell-value'>{sex(patient.sex)}</td>
          </tr>
          <tr>
            <td className='td-cell-label'>{t('label.age')}:</td>
            <td className='td-cell-value'>{patient.age}</td>
          </tr>
          <tr>
            <td className='td-cell-label'>CDR:</td>
            <td className='td-cell-value'>{patient.cdr}</td>
          </tr>
          <tr>
            <td className='td-cell-label'>SOB:</td>
            <td className='td-cell-value'>{Math.max(0, patient.sob)}</td>
          </tr>
          <tr>
            <td className='td-cell-label'>MMSE:</td>
            <td className='td-cell-value'>
              {patient.mmse !== null ? Math.max(0, patient.mmse) : t('label.not_available')}
            </td>
          </tr>
          <tr>
            <td className='td-cell-label'>ApoE:</td>
            <td className='td-cell-value'>{patient.apoe}</td>
          </tr>
          <tr>
            <td className='td-cell-label'>Amyloid PET:</td>
            <td className='td-cell-value'>{naPosNeg(patient.amyloidPet)}</td>
          </tr>
          {patient.probCtrl !== null && (
            <tr>
              <td className='td-cell-label'>{t('label.prob_ctrl')}:</td>
              <td className='td-cell-value'>{((patient.probCtrl ?? 0) * 100).toFixed(1)} %</td>
            </tr>
          )}
          {patient.probMci !== null && (
            <tr>
              <td className='td-cell-label'>{t('label.prob_mci')}:</td>
              <td className='td-cell-value'>{((patient.probMci ?? 0) * 100).toFixed(1)} %</td>
            </tr>
          )}
          {patient.probAd !== null && (
            <tr>
              <td className='td-cell-label'>{t('label.prob_ad')}:</td>
              <td className='td-cell-value'>{((patient.probAd ?? 0) * 100).toFixed(1)} %</td>
            </tr>
          )}
          {patient.neuroEval && (
            <tr>
              <td className='td-cell-label'>{t('label.neurological_conclusion')}:</td>
              <td className='td-cell-value'>{translate(patient.neuroEval)}</td>
            </tr>
          )}
        </tbody>
      </table>

      <button type='button' onClick={onConfirm}>
        {t('button.yes_save_changes')}
      </button>
      <button type='button' onClick={onCancel}>
        {t('button.no_take_me_back')}
      </button>
    </>
  )
}

export default PatientConfirmation
