import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLogout } from '../hooks/useLogout'
import { useDataContext } from '../contexts/DataContext'

const NavBar = () => {
  const { currentUserId, currentUserRole, currentUserFullName, currentUserCode } = useDataContext()
  const { t } = useTranslation()
  const logout = useLogout()
  const navigate = useNavigate()

  const signOut = () => {
    logout()
    navigate('/')
  }

  if (!currentUserId) return <></>

  return (
    <nav>
      <p className='user-name'>
        {currentUserFullName}
        {currentUserCode && (
          <>
            <br />
            <span className='user-name'>{currentUserCode}</span>
          </>
        )}
      </p>
      <ul>
        <li>
          <Link to='/'>{t('nav.home')}</Link>
        </li>
        <li>
          <Link to='/changepassword'>{t('nav.change_password')}</Link>
        </li>
        <li>
          <Link to='/patient/list'>{t('nav.patient.list')}</Link>
        </li>
        {currentUserRole === 'client' && (
          <li>
            <Link to='/patient/add'>{t('nav.patient.add')}</Link>
          </li>
        )}
        {currentUserRole === 'admin' && (
          <li>
            <Link to='/user/list'>{t('nav.user.list')}</Link>
          </li>
        )}
        {currentUserRole === 'admin' && (
          <li>
            <Link to='/user/add'>{t('nav.user.add')}</Link>
          </li>
        )}
        <li>
          <Link to='/legal'>{t('nav.legal')}</Link>
        </li>
      </ul>
      {currentUserId && (
        <button type='button' className='nav-button' onClick={signOut}>
          {t('login.sign_out')}
        </button>
      )}
    </nav>
  )
}

export default NavBar
