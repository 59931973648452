const Footer = () => {
  return (
    <footer className='report'>
      <p>
        ADmit Therapeutics, S.L. &ndash; Carrer Joan XXIII, 10 &ndash; 08950 Esplugues de Llobregat &ndash; Barcelona
        &ndash; Spain
      </p>
      <p>+34 938 015 348 &ndash; mapad@admit-therapeutics.com</p>
      <img src={require('../images/admitLogo.png')} alt='ADmit logo' />
    </footer>
  )
}

export default Footer
