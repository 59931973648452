import { deleteToken, getToken, saveToken } from './localStorage'
import LoginRequest from '../models/LoginRequest'
import LoginResponse from '../models/LoginResponse'
import Patient from '../models/Patient'
import Translation from '../models/Translation'
import User from '../models/User'

export const host = process.env.REACT_APP_API_HOST

const api = host + 'api/v1/'

const requestHeaders = { Accept: '*/*', 'Content-Type': 'application/json' }

const error400 = (response: Response) =>
  response.status === 400 ? `400/${response.headers.get('X-Error-Code')}` ?? '000' : ''

//#region Authentication

export const authenticate = async (loginRequest: LoginRequest) => {
  const body = JSON.stringify({
    username: loginRequest.username,
    password: loginRequest.password,
  })

  const response = await fetch(`${api}login/authenticate`, {
    method: 'POST',
    headers: requestHeaders,
    body,
  })

  return await processAuthentication(response)
}

export const reauthenticate = async () => {
  const headers = { ...requestHeaders, Authorization: 'Bearer ' + getToken() }
  const response = await fetch(`${api}login/refreshtoken`, { method: 'GET', headers })
  return await processAuthentication(response)
}

const processAuthentication = async (response: Response) => {
  if (response.status !== 200) {
    deleteToken()
    console.error(`Authentication failed ${error400(response)}`)
    return null
  }

  const loginResponse = (await response.json()) as LoginResponse
  saveToken(loginResponse.token)
  return loginResponse
}

const mustReauthenticate = () => {
  // Split the token into its parts.
  const token = getToken()
  if (!token) return true
  const parts = token.split('.')
  if (parts.length !== 3) return true

  // Decode the payload part (it's always the second part in a JWT).
  const payload = parts[1]
  const base64Correction = payload.replace(/-/g, '+').replace(/_/g, '/')
  const decodedPayload = atob(base64Correction)

  // Parse the decoded payload to an object and check if token was issued more than 20 seconds ago.
  const parsedPayload = JSON.parse(decodedPayload)
  return new Date().getTime() / 1000 - parsedPayload.iat > 20
}

//#endregion

//#region Standard API calls (CRUD)

export async function getAll<T extends Patient | User | Translation>(endpoint: string) {
  if (mustReauthenticate()) {
    if (!(await reauthenticate())) return { status: 401, data: [], error: '' }
  }

  const headers = { ...requestHeaders, Authorization: 'Bearer ' + getToken() }
  const response = await fetch(api + endpoint, { method: 'GET', headers })

  const data: T[] = response.status === 200 ? await response.json() : []
  const error = error400(response)

  return { status: response.status, data, error }
}

export async function getOne<T extends Patient | User>(endpoint: string, id: string) {
  if (mustReauthenticate()) {
    if (!(await reauthenticate())) return { status: 401, data: null, error: '' }
  }

  const headers = { ...requestHeaders, Authorization: 'Bearer ' + getToken() }
  const response = await fetch(api + endpoint + id, { method: 'GET', headers })

  const data: T | null = response.status === 200 ? await response.json() : null
  const error = error400(response)

  return { status: response.status, data, error }
}

export async function addOne<T extends Patient | User>(endpoint: string, element: T) {
  if (mustReauthenticate()) {
    if (!(await reauthenticate())) return { status: 401, data: null, error: '' }
  }

  const headers = { ...requestHeaders, Authorization: 'Bearer ' + getToken() }
  const response = await fetch(api + endpoint, { method: 'POST', body: JSON.stringify(element), headers })

  const data: T | null = response.status === 201 ? await response.json() : null
  const error = error400(response)

  return { status: response.status, data, error }
}

export async function updateOne<T extends Patient | User>(endpoint: string, element: T) {
  if (mustReauthenticate()) {
    if (!(await reauthenticate())) return { status: 401, data: null, error: '' }
  }

  const headers = { ...requestHeaders, Authorization: 'Bearer ' + getToken() }
  const response = await fetch(api + endpoint + element.id, { method: 'PUT', body: JSON.stringify(element), headers })

  const data: T | null = response.status === 204 ? element : null
  const error = error400(response)

  return { status: response.status, data, error }
}

export async function deleteOne(endpoint: string, id: string) {
  if (mustReauthenticate()) {
    if (!(await reauthenticate())) return { status: 401, success: false, error: '' }
  }

  const headers = { ...requestHeaders, Authorization: 'Bearer ' + getToken() }
  const response = await fetch(api + endpoint + id, { method: 'DELETE', headers })

  const error = error400(response)

  return { status: response.status, success: response.status === 204, error }
}

//#endregion

//#region Special API calls

export async function uploadFile(file: File) {
  if (mustReauthenticate()) {
    if (!(await reauthenticate())) return { status: 401, data: null, error: '' }
  }

  const formData = new FormData()
  formData.append('file', file, file.name)

  const endpoint = 'files/upload'
  const headers = { Accept: '*/*', Authorization: 'Bearer ' + getToken() }
  const response = await fetch(api + endpoint, { method: 'POST', body: formData, headers })

  const data: string | null = response.status === 200 ? (await response.json()).fileName : null
  const error = error400(response)

  return { status: response.status, data, error }
}

export async function getPdfReport(patientId: string) {
  if (mustReauthenticate()) {
    if (!(await reauthenticate())) return { status: 401, data: null, error: '' }
  }

  const endpoint = 'files/b71f97b1-62b4-47a1-a397-09f3c8c71626/'
  const headers = { ...requestHeaders, Authorization: 'Bearer ' + getToken() }
  const response = await fetch(api + endpoint + patientId, { method: 'GET', headers })

  const data = response.status === 200 ? await response.blob() : null
  const error = error400(response)

  return { status: response.status, data, error }
}

export async function changePassword(id: string, oldPassword: string, newPassword: string) {
  if (mustReauthenticate()) {
    if (!(await reauthenticate())) return { status: 401, success: false, error: '' }
  }

  const headers = { ...requestHeaders, Authorization: 'Bearer ' + getToken() }
  const response = await fetch(`${api}users/changepassword/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ oldPassword, newPassword }),
    headers,
  })

  const error = error400(response)

  return { status: response.status, success: response.status === 204, error }
}

export async function softwareVersion() {
  const response = await fetch(`${api}version`)
  return response.status === 200 ? (await response.text()).replaceAll('"', '') : 'unknown'
}

//#endregion
