const TOKEN = '740e2887b5e3'
const LANGUAGE = '674efd362326'
const LAST_ACTIVITY = 'e3a4c5d6f7g8'

export const saveToken = (token: string) => localStorage.setItem(TOKEN, token)
export const getToken = () => localStorage.getItem(TOKEN)
export const deleteToken = () => localStorage.removeItem(TOKEN)
export const token = TOKEN

export const saveLanguage = (language: string) => localStorage.setItem(LANGUAGE, language)
export const getLanguage = () => localStorage.getItem(LANGUAGE)

// A session is alive if the last activity was less than 4 seconds ago even if there was no tab open during that time.
export const touchSession = () => localStorage.setItem(LAST_ACTIVITY, new Date().getTime().toString())
export const isSessionAlive = () => new Date().getTime() - +(localStorage.getItem(LAST_ACTIVITY) ?? 0) < 4000
