import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormatter } from '../../hooks/useFormatter'
import { useDataContext } from '../../contexts/DataContext'
import User from '../../models/User'

type Props = {
  user: User
}

const UserTableRow = ({ user }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { dateTime, infoRole } = useFormatter()
  const { currentUserId } = useDataContext()

  const editUser = () => {
    navigate(`/user/${user.id}`)
  }

  return (
    <tr>
      <td>{user.username}</td>
      <td>{user.firstName}</td>
      <td>{user.lastName}</td>
      <td>{user.email}</td>
      <td>{infoRole(user.role)}</td>
      <td className='td-flex'>
        <div className='truncated'>{dateTime(user.created ?? '')}</div>
        {user.id !== currentUserId && (
          <div>
            <button type='button' onClick={editUser}>
              {t('button.edit')}
            </button>
          </div>
        )}
      </td>
    </tr>
  )
}

export default UserTableRow
