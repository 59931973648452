import { createContext, ReactNode, useContext, useState } from 'react'

type ConfirmationContextType = {
  deleteConfirmed: boolean
  setDeleteConfirmed: React.Dispatch<React.SetStateAction<boolean>>
  deleteConfirmationText: string | null
  setDeleteConfirmationText: React.Dispatch<React.SetStateAction<string | null>>
  resetPasswordConfirmed: boolean
  setResetPasswordConfirmed: React.Dispatch<React.SetStateAction<boolean>>
  resetPasswordConfirmationText: string | null
  setResetPasswordConfirmationText: React.Dispatch<React.SetStateAction<string | null>>
}

type ConfirmationProviderProps = {
  children: ReactNode
}

const ConfirmationContext = createContext({} as ConfirmationContextType)

const ConfirmationProvider = ({ children }: ConfirmationProviderProps) => {
  const [deleteConfirmed, setDeleteConfirmed] = useState(false)
  const [deleteConfirmationText, setDeleteConfirmationText] = useState<string | null>(null)
  const [resetPasswordConfirmed, setResetPasswordConfirmed] = useState(false)
  const [resetPasswordConfirmationText, setResetPasswordConfirmationText] = useState<string | null>(null)

  return (
    <ConfirmationContext.Provider
      value={{
        deleteConfirmed,
        setDeleteConfirmed,
        deleteConfirmationText,
        setDeleteConfirmationText,
        resetPasswordConfirmed,
        setResetPasswordConfirmed,
        resetPasswordConfirmationText,
        setResetPasswordConfirmationText,
      }}
    >
      {children}
    </ConfirmationContext.Provider>
  )
}

export const useConfirmationContext = () => useContext(ConfirmationContext)

export default ConfirmationProvider
