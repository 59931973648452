type Props = {
  id: string
  text: string
  checked: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Checkbox = ({ id, text, checked, onChange }: Props) => {
  return (
    <>
      <label htmlFor={id}>{text}</label>
      <input type='checkbox' id={id} name={id} checked={checked} onChange={onChange} />
    </>
  )
}

export default Checkbox
